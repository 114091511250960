/* eslint-disable @typescript-eslint/no-unused-vars */
import React, { forwardRef, useCallback, useMemo, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useMarkNotificationsAsReadMutation } from "../../redux/api-slice";
import { ArrowLeftIcon } from "@heroicons/react/20/solid";
import {
  InformationCircleIcon,
  ChatBubbleLeftRightIcon,
  ChevronDownIcon,
  ChevronLeftIcon,
  UserIcon,
  UserCircleIcon,
} from "@heroicons/react/24/outline";
import { useLocation, useNavigate } from "react-router-dom";
import {
  selectCredits,
  selectCurrentDocument,
  setHelpOpen,
  setModal,
} from "../../redux/application-slice";
import { selectCurrentProject } from "../../redux/application-slice";
import {
  Container,
  Paper,
  Divider,
  Tooltip,
  Drawer,
  Text,
  ScrollArea,
} from "@mantine/core";
import { useWorkflow } from "../../hooks/use-workflow";
import { Workflow } from "../workflows/workflow-popover/workflow-popover";
import { MenuPopover } from "./menu-popover";
import { useElementWidth } from "../../hooks/use-element-width";
import { useMenuOptions } from "../../hooks/use-topbar-menu-options-data";
import { truncateText } from "../../utils/truncate-text";
import FAQDrawerContent from "../modals/faq-content";
import { useDisclosure } from "@mantine/hooks";
import { MODAL_TYPES } from "../modals/modal-controller";
import posthog from "posthog-js";
import { POSTHOG } from "../../utils/posthog-constants";

const ProjectsButtonMenuTarget = forwardRef<
  HTMLDivElement,
  { maxSelectorWidth: number }
>(({ maxSelectorWidth }, ref) => {
  const currentProject = useSelector(selectCurrentProject);
  return (
    <Paper
      radius="md"
      p="6px"
      bg="#ffffff00"
      data-testid="Projects Dropdown Button"
      className="group cursor-not-allowed border-2 border-[#ffffff00] transition-colors hover:border-2 hover:border-[#e5e7eb] hover:!bg-[#fff]"
      ref={ref}
    >
      <div className="flex items-center justify-center text-[16px] !text-gray-400 group-hover:!text-black">
        <svg
          xmlns="http://www.w3.org/2000/svg"
          width="24"
          height="24"
          className="mr-2"
          fill="currentColor"
          viewBox="0 0 256 256"
        >
          {currentProject ? (
            <path d="M216,72H131.31L104,44.69A15.86,15.86,0,0,0,92.69,40H40A16,16,0,0,0,24,56V200.62A15.4,15.4,0,0,0,39.38,216H216.89A15.13,15.13,0,0,0,232,200.89V88A16,16,0,0,0,216,72ZM40,56H92.69l16,16H40ZM216,200H40V88H216Z"></path>
          ) : (
            <path d="M96,208a8,8,0,0,1-8,8H39.38A15.4,15.4,0,0,1,24,200.62V192a8,8,0,0,1,16,0v8H88A8,8,0,0,1,96,208Zm64-8H128a8,8,0,0,0,0,16h32a8,8,0,0,0,0-16Zm64-56a8,8,0,0,0-8,8v48H200a8,8,0,0,0,0,16h16.89A15.13,15.13,0,0,0,232,200.89V152A8,8,0,0,0,224,144Zm-8-72H168a8,8,0,0,0,0,16h48v24a8,8,0,0,0,16,0V88A16,16,0,0,0,216,72ZM24,80V56A16,16,0,0,1,40,40H92.69A15.86,15.86,0,0,1,104,44.69l29.66,29.65A8,8,0,0,1,128,88H32A8,8,0,0,1,24,80Zm16-8h68.69l-16-16H40Zm-8,88a8,8,0,0,0,8-8V120a8,8,0,0,0-16,0v32A8,8,0,0,0,32,160Z"></path>
          )}
        </svg>
        {truncateText(
          maxSelectorWidth,
          currentProject ? currentProject.project.title : "No Selection"
        )}
        <ChevronDownIcon className="mx-2 h-6 w-6 text-gray-400 group-hover:text-black" />
      </div>
    </Paper>
  );
});
ProjectsButtonMenuTarget.displayName = "ProjectsButtonMenuTarget";

const DocumentsButtonMenuTarget = forwardRef<
  HTMLDivElement,
  { maxSelectorWidth: number }
>(({ maxSelectorWidth }, ref) => {
  const currentDocument = useSelector(selectCurrentDocument);
  return (
    <Paper
      radius="md"
      p="6px"
      bg="#ffffff00"
      className="group cursor-pointer border-2 border-[#ffffff00] bg-[#dadada] transition-colors hover:border-2 hover:border-[#e5e7eb] hover:!bg-[#fff]"
      ref={ref}
    >
      <div className="flex items-center justify-center text-[16px] !text-gray-500 group-hover:!text-black ">
        {currentDocument ? (
          <div className={`flex items-center max-w-[${maxSelectorWidth}px]`}>
            <svg
              xmlns="http://www.w3.org/2000/svg"
              width="20"
              height="20"
              className="mr-1"
              fill="currentColor"
              viewBox="0 0 256 256"
            >
              <path d="M213.66,82.34l-56-56A8,8,0,0,0,152,24H56A16,16,0,0,0,40,40V216a16,16,0,0,0,16,16H200a16,16,0,0,0,16-16V88A8,8,0,0,0,213.66,82.34ZM160,51.31,188.69,80H160ZM200,216H56V40h88V88a8,8,0,0,0,8,8h48V216Z"></path>
            </svg>
            {truncateText(maxSelectorWidth, currentDocument.title)}
          </div>
        ) : (
          <div className={`flex items-center max-w-[${maxSelectorWidth}px]`}>
            <svg
              className="mr-1"
              fill="currentColor"
              xmlns="http://www.w3.org/2000/svg"
              width="20"
              height="20"
              viewBox="0 0 256 256"
            >
              <path d="M80,224a8,8,0,0,1-8,8H56a16,16,0,0,1-16-16V184a8,8,0,0,1,16,0v32H72A8,8,0,0,1,80,224ZM216,88v48a8,8,0,0,1-16,0V96H152a8,8,0,0,1-8-8V40H120a8,8,0,0,1,0-16h32a8,8,0,0,1,5.66,2.34l56,56A8,8,0,0,1,216,88Zm-56-8h28.69L160,51.31ZM80,24H56A16,16,0,0,0,40,40V64a8,8,0,0,0,16,0V40H80a8,8,0,0,0,0-16ZM208,168a8,8,0,0,0-8,8v40h-8a8,8,0,0,0,0,16h8a16,16,0,0,0,16-16V176A8,8,0,0,0,208,168ZM48,152a8,8,0,0,0,8-8V104a8,8,0,0,0-16,0v40A8,8,0,0,0,48,152Zm104,64H112a8,8,0,0,0,0,16h40a8,8,0,0,0,0-16Z"></path>
            </svg>
            {truncateText(maxSelectorWidth, "No Selection")}
          </div>
        )}
        <ChevronDownIcon className="ml-2 h-6 w-6 text-gray-500 group-hover:text-black" />
      </div>
    </Paper>
  );
});

DocumentsButtonMenuTarget.displayName = "DocumentsButtonMenuTarget";

const NotificationButtonMenuTarget = forwardRef<
  HTMLDivElement,
  { onClickNotificationsButton: () => void }
>(({ onClickNotificationsButton }, ref) => {
  const dispatch = useDispatch();
  const currentCredits = useSelector(selectCredits);
  const openBookAMeetingModal = () => {
    posthog?.capture(POSTHOG.book_meeting_clicked, {
      source: "nav_header",
      credits_remaining: currentCredits,
    });
    dispatch(setModal({ modal: MODAL_TYPES.BOOK_A_MEETING }));
  };

  return (
    <Paper
      radius="md"
      p="6px"
      px="8px"
      bg="#ffffff00"
      className="relative cursor-pointer flex-shrink-0 min-w-0 border-2 border-[#e5e7eb] !bg-[#fff]"
      onClick={openBookAMeetingModal}
      ref={ref}
    >
      <div className="flex items-center justify-center text-[16px] text-black">
        <UserCircleIcon className="mr-2 h-6 w-6 text-black" />
        Book a Call with Provision
      </div>
    </Paper>
  );
});
NotificationButtonMenuTarget.displayName = "NotificationButtonMenuTarget";
const AccountButtonMenuTarget = forwardRef<HTMLDivElement, {}>((props, ref) => {
  return (
    <Tooltip
      label={
        <>
          <span className="font-semibold">
            Adjust Personal and Organizational Settings.
          </span>
          <br />
          Only available in paid Provision accounts. <br /> Please reach out to
          learn more!
        </>
      }
      position="bottom"
      w="330px"
      multiline
    >
      <Paper
        radius="md"
        p="6px"
        bg="#ffffff00"
        className="group cursor-not-allowed border-2 border-[#ffffff00] transition-colors hover:border-2 hover:border-[#e5e7eb] hover:!bg-[#fff]"
        ref={ref}
      >
        <div className="flex items-center justify-center text-[16px] text-gray-400 group-hover:text-black">
          <UserIcon className="mr-2 h-6 w-6 text-gray-400 group-hover:text-black" />
          Account
        </div>
      </Paper>
    </Tooltip>
  );
});
AccountButtonMenuTarget.displayName = "AccountButtonMenuTarget";
// eslint-disable-next-line complexity
function NavHeader() {
  const credits = useSelector(selectCredits);

  const { DOCUMENT_DROPDOWN_OPTIONS } = useMenuOptions();
  const navigate = useNavigate();
  const currentProject = useSelector(selectCurrentProject);

  const { pathname } = useLocation();
  const dispatch = useDispatch();
  const [markNotificationsAsRead] = useMarkNotificationsAsReadMutation();
  const [referenceElement] = useState<HTMLElement | null>(null);

  const onBackButton = () => {
    if (currentProject?.uuid) {
      navigate(`/${currentProject?.uuid}/comments`);
    }
  };

  const showCommentViewerBackButtom = useMemo(
    () => pathname.endsWith("commentviewer"),
    [pathname]
  );

  const showBackButton = useMemo(
    // Editors Note: this is a poor pattern but since our app is largely one-page, I think it's reasonable.
    // More than 2 is not reasonable, and if we introduce a new page, please refactor this.
    () => pathname.endsWith("settings") || pathname.endsWith("notifications"),
    [pathname]
  );

  const onOpenHelp = useCallback(() => {
    window.$chatwoot.toggle("open");
    dispatch(setHelpOpen(false));
    referenceElement?.click();
  }, [dispatch, referenceElement]);

  const { workflowMatch } = useWorkflow();
  const { Icon } = workflowMatch as Workflow;

  const [isNotificationsOpen, setIsNotificationsOpen] = useState(false);

  // OnClick method for root Notifications button that clears unread statuses for all children
  const [unreadNotifications, setUnreadNotifications] = useState<string[]>([]);
  const onClickNotificationsButton = useCallback(() => {
    if (unreadNotifications.length > 0) {
      markNotificationsAsRead(unreadNotifications);
    }
    setIsNotificationsOpen(!isNotificationsOpen);
  }, [isNotificationsOpen, unreadNotifications, markNotificationsAsRead]);

  const useNavigateToPreviousPage = useCallback(() => {
    navigate(-1);
  }, [navigate]);

  const HelpButton = () => (
    <Paper
      onClick={onOpenHelp}
      radius="md"
      p="6px"
      bg="#ffffff00"
      className="group cursor-pointer border-2 border-[#ffffff00] transition-colors hover:border-2 hover:border-[#e5e7eb] hover:!bg-[#fff]"
    >
      <div className="flex items-center justify-center text-[16px] text-gray-500 group-hover:text-black">
        <ChatBubbleLeftRightIcon className="mr-2 h-6 w-6 text-gray-500 group-hover:text-black" />
        Live Chat
      </div>
    </Paper>
  );
  const [opened, { open, close }] = useDisclosure(false);

  const FAQButton = () => {
    const dispatch = useDispatch();

    const openFAQModal = () => {
      dispatch(setModal({ modal: MODAL_TYPES.FAQ }));
    };

    return (
      <Paper
        onClick={openFAQModal}
        radius="md"
        p="6px"
        bg="#ffffff00"
        className="group cursor-pointer border-2 border-[#ffffff00] transition-colors hover:border-2 hover:border-[#e5e7eb] hover:!bg-[#fff]"
      >
        <div className="flex items-center justify-center text-[16px] text-gray-500 group-hover:text-black">
          <InformationCircleIcon className="mr-2 h-6 w-6 text-gray-500 group-hover:text-black" />
          FAQs
        </div>
      </Paper>
    );
  };

  const FAQDrawerTrigger = ({
    opened,
    close,
  }: {
    opened: boolean;
    close: () => void;
  }) => (
    <Drawer
      transitionProps={{
        transition: "rotate-left",
        duration: 500,
        timingFunction: "linear",
      }}
      scrollAreaComponent={ScrollArea.Autosize}
      opened={opened}
      onClose={close}
      title={
        <div className="flex flex-col items-center">
          <Text size="2.25rem" fw={700} ta="center" mt="md" mb="xs">
            What is Provision?
          </Text>
          <Text size="lg" c="dimmed" ta="center" pt="sm" maw="90%">
            Provision helps constructors win more of the right projects by
            highlighting risks quickly and answering questions about project
            documents.
          </Text>
          <Divider />
        </div>
      }
      padding="xl"
      size="xl"
      position="left"
      overlayProps={{ backgroundOpacity: 0.2, blur: 4 }}
    >
      <FAQDrawerContent />
    </Drawer>
  );

  // Used to truncate the display text of the Projects and Documents dropdowns
  const [containerRef, containerWidth] = useElementWidth();
  const maxSelectorWidth = useMemo(
    () => containerWidth / 2 - 16,
    [containerWidth]
  );

  const BackButton = () => (
    <div className="flex items-center [grid-area:1_/_1_/_1_/_2]">
      <Paper
        radius="md"
        p="6px"
        bg="#ffffff00"
        className="group ml-4 cursor-pointer border-2  border-[#dfe2e8] !bg-[#fafafa] transition-colors hover:border-blue-500 hover:!bg-[#fff]"
        onClick={useNavigateToPreviousPage}
      >
        <div className="mr-2 flex items-center justify-center text-[16px] font-medium !text-black group-hover:!text-blue-900">
          <ChevronLeftIcon className="mr-2 h-6 w-6 text-black group-hover:text-blue-900" />
          {/* TODO make this dynamic and jump to last browser history page */}
          Go Back to Home
        </div>
      </Paper>
    </div>
  );

  return (
    <div className="relative z-50 flex grid h-14 grid-cols-[420px_1fr_280px] grid-rows-[1fr] items-center justify-between border-b border-gray-300 bg-[#f2f5fb] px-1.5">
      <div className="flex justify-center gap-2 ">
        {/* TODO reconsile this button with the rest of the changes */}
      </div>

      {/* Left side menus & buttons */}
      {showBackButton ? (
        <BackButton />
      ) : (
        <div className="flex items-center [grid-area:1_/_1_/_1_/_2]">
          <Container
            className="flex-start flex w-full flex-row"
            ref={containerRef}
          >
            <Tooltip
              label={
                <>
                  <span className="font-semibold">
                    Create Multiple Projects for Separate Initiatives.
                  </span>
                  <br />
                  Only available in paid Provision accounts. <br /> Please reach
                  out to learn more!
                </>
              }
              position="bottom"
              w="350px"
              multiline
            >
              <ProjectsButtonMenuTarget maxSelectorWidth={maxSelectorWidth} />
            </Tooltip>

            <MenuPopover
              topLabel={
                DOCUMENT_DROPDOWN_OPTIONS.length === 1
                  ? "No Documents Found"
                  : "Viewing All Documents in This Folder"
              }
              width={500}
              options={DOCUMENT_DROPDOWN_OPTIONS}
              type="Document"
            >
              <DocumentsButtonMenuTarget maxSelectorWidth={maxSelectorWidth} />
            </MenuPopover>
          </Container>
        </div>
      )}

      {/* All Workflows */}
      <div
        className={
          "flex items-center justify-center px-2 [grid-area:1_/_2_/_2_/_3]"
        }
      >
        <NotificationButtonMenuTarget
          onClickNotificationsButton={onClickNotificationsButton}
        />

        {/* These two elements should never coincide, and thus it's okay for them to share the same container */}
        {showCommentViewerBackButtom && (
          <button
            className={
              "flex items-center rounded border border-gray-400 bg-white px-2 py-1 text-sm transition-colors hover:border-blue-500 hover:bg-sky-50 hover:text-blue-500"
            }
            onClick={onBackButton}
          >
            <ArrowLeftIcon width={"18"} className="mr-1" />
            <span>Back to Comments App</span>
          </button>
        )}
      </div>

      <div
        className={
          "flex items-center justify-end px-2 [grid-area:1_/_3_/_2_/_4]"
        }
      >
        <HelpButton />

        <FAQButton />
        <FAQDrawerTrigger opened={opened} close={close} />
      </div>
    </div>
  );
}

export default NavHeader;
