import React, { Fragment } from "react";
import { Dialog, Transition } from "@headlessui/react";
import { useDispatch } from "react-redux";
import { setModal } from "../../redux/application-slice";
import FAQContent from "./faq-content";
import { Button, Box, Divider, Text } from "@mantine/core";

interface FAQModalProps {
  open: boolean;
}

function FAQModal({ open }: FAQModalProps) {
  const dispatch = useDispatch();

  const onClose = () => {
    dispatch(setModal(null));
  };

  const dialogContent = (
    <Dialog.Panel className="relative transform overflow-hidden rounded-lg bg-white text-left shadow-xl transition-all sm:my-8 sm:w-[800px] sm:h-[80vh]">
      <div className="sticky top-0 z-10 bg-gray-50">
        <div className="flex flex-col items-center p-8 pb-4">
          <Text size="2.25rem" fw={700} ta="center" mt="md" mb="xs">
            What is Provision?
          </Text>
          <Text size="lg" c="dimmed" ta="center" pt="sm" mb="lg" maw="90%">
            Provision helps constructors win more of the right projects by
            highlighting risks quickly and answering questions about project
            documents.
          </Text>
        </div>
        <Divider />
      </div>
      <div className="overflow-y-auto h-[calc(80vh-320px)]">
        <div className="p-8 pt-2">
          <FAQContent />
        </div>
      </div>
      <Box
        mt="xl"
        p="xl"
        pb="lg"
        style={{
          position: "sticky",
          bottom: 0,
          background: "white",
          padding: "1rem 0 0 0",
        }}
      >
        <Button
          h="48px"
          fullWidth
          color="yellow"
          onClick={() => {
            if (window.$chatwoot) {
              window.$chatwoot.toggle();
            }
          }}
        >
          <span className="text-lg text-black">
            Ask us Anything! We're happy to help.
          </span>
        </Button>
      </Box>
    </Dialog.Panel>
  );

  return (
    <Transition.Root show={open} as={Fragment}>
      <Dialog as="div" className="relative z-50" onClose={onClose}>
        <Transition.Child
          as={Fragment}
          enter="ease-out duration-300"
          enterFrom="opacity-0"
          enterTo="opacity-100"
          leave="ease-in duration-200"
          leaveFrom="opacity-100"
          leaveTo="opacity-0"
        >
          <div className="fixed inset-0 bg-gray-500 bg-opacity-75 transition-opacity" />
        </Transition.Child>

        <div className="fixed inset-0 z-10 overflow-y-auto">
          <div className="flex min-h-full items-end justify-center p-4 text-center sm:items-center sm:p-0">
            <Transition.Child
              as={Fragment}
              enter="ease-out duration-300"
              enterFrom="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
              enterTo="opacity-100 translate-y-0 sm:scale-100"
              leave="ease-in duration-200"
              leaveFrom="opacity-100 translate-y-0 sm:scale-100"
              leaveTo="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
            >
              {dialogContent}
            </Transition.Child>
          </div>
        </div>
      </Dialog>
    </Transition.Root>
  );
}

export default FAQModal;
