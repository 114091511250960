import { RiskSort } from "../../components/workflows/risk-review/risk-review-sort-listbox";
import {
  ProjectRisk,
  ProjectRiskComment,
  ProjectRiskFeedback,
  RiskRanking,
  RiskStatus,
} from "../../shared/interfaces/project/risk/risk-inteface";
import { buildQueryParams } from "../../utils/build-query-params";
import { apiSlice } from "../api-slice";
import {
  RiskList,
  CreateRiskList,
  UpdateRiskList,
  CustomRisk,
  CreateCustomRisk,
  UpdateCustomRisk,
  RunRiskListReviewArgs,
  RiskListPatch,
} from "../../shared/interfaces/project/risk/risk-list-interface";
export interface ProjectRiskStats {
  count: number;
  risk_ranking: RiskRanking;
}

export interface ProjectRiskStatusStats {
  count: number;
  status?: RiskStatus;
}

export interface RiskPipelineStats {
  count: number;
}

export interface GetProjectRisks {
  projectUUID: string;
  status?: RiskStatus;
  sort?: string;
}

export interface ProjectRiskUpdate {
  id: string;
  status?: RiskStatus;
  riskRanking?: RiskRanking;
  projectUUID: string;
  riskStatusFilter?: RiskStatus;
  sort?: RiskSort;
}

const projectRiskApi = apiSlice.injectEndpoints({
  endpoints: (builder) => ({
    getProjectRisk: builder.query<ProjectRisk[], GetProjectRisks>({
      query: ({ projectUUID, status, sort }) => {
        const queryParams = buildQueryParams({
          status,
          project: projectUUID,
          sort,
        });
        return `/projectrisks/?${queryParams.toString()}`;
      },
      providesTags: (result) =>
        result
          ? [
              ...result.map(({ id }) => ({
                type: "ProjectRisk" as const,
                id,
              })),
              { type: "ProjectRisk" as const, id: "LIST" },
            ]
          : [{ type: "ProjectRisk" as const, id: "LIST" }],
    }),
    getProjectRiskStats: builder.query<ProjectRiskStats[], string>({
      query: (projectUUID) => {
        const queryParams = buildQueryParams({
          project: projectUUID,
        });
        return `/projectrisks/stats/?${queryParams.toString()}`;
      },
      transformResponse: (response: ProjectRiskStats[]) => {
        return response.map((projectRiskStat) => {
          return {
            ...projectRiskStat,
            risk_ranking: parseInt(projectRiskStat.risk_ranking.toString()),
          };
        }, []);
      },
    }),
    getProjectRiskStatusStats: builder.query<ProjectRiskStatusStats[], string>({
      query: (projectUUID) => {
        const queryParams = buildQueryParams({
          project: projectUUID,
        });
        return `/projectrisks/status_stats/?${queryParams.toString()}`;
      },
      transformResponse: (response: ProjectRiskStatusStats[]) => {
        return response.map((projectRiskStat) => {
          return {
            ...projectRiskStat,
            status: parseInt(projectRiskStat?.status?.toString() ?? ""),
          };
        }, []);
      },
    }),
    getProjectRisksProcessingStatus: builder.query<RiskPipelineStats, string>({
      query: (projectUUID) => {
        const queryParams = buildQueryParams({
          project: projectUUID,
        });
        return `/projectrisks/stats_analysing/?${queryParams.toString()}`;
      },
    }),
    updateProjectRisk: builder.mutation<ProjectRisk, ProjectRiskUpdate>({
      query: ({ id, status, riskRanking, projectUUID }) => {
        const queryParams = buildQueryParams({
          project: projectUUID,
        });
        return {
          url: `/projectrisks/${id}/?${queryParams.toString()}`,
          method: "PATCH",
          body: {
            status: status,
            risk_ranking: riskRanking,
          },
        };
      },
      async onQueryStarted(
        { id, status, riskRanking, riskStatusFilter, projectUUID, sort },
        { dispatch, queryFulfilled }
      ) {
        const patchResult = dispatch(
          projectRiskApi.util.updateQueryData(
            "getProjectRisk",
            { projectUUID, status: riskStatusFilter, sort },
            (draft) => {
              const riskToUpdate = draft.find((risk) => risk.id === id);
              if (riskToUpdate) {
                if (status !== undefined) {
                  riskToUpdate.status = status;
                }
                if (riskRanking !== undefined) {
                  riskToUpdate.risk_ranking = riskRanking;
                }
              }
            }
          )
        );
        try {
          const { data } = await queryFulfilled;
          // If the server returns updated data, we can update the cache with it
          dispatch(
            projectRiskApi.util.updateQueryData(
              "getProjectRisk",
              { projectUUID, status: riskStatusFilter, sort },
              (draft) => {
                const index = draft.findIndex((risk) => risk.id === id);
                if (index !== -1) {
                  draft[index] = data;
                }
              }
            )
          );
        } catch {
          patchResult.undo();
        }
      },
    }),
    createProjectRiskComment: builder.mutation<
      ProjectRiskComment,
      ProjectRiskComment
    >({
      query: (projectRiskComment) => ({
        url: "/projectriskcomments/",
        method: "POST",
        body: projectRiskComment,
      }),
      async onQueryStarted(projectRiskComment, { dispatch, queryFulfilled }) {
        const patchResult = dispatch(
          projectRiskApi.util.updateQueryData(
            "getProjectRisk",
            projectRiskComment.project_uuid
              ? {
                  projectUUID: projectRiskComment.project_uuid,
                  sort: projectRiskComment.sort,
                }
              : { projectUUID: "" },
            (draft) => {
              const projectRisk = projectRiskComment.parent;
              if (!projectRisk) {
                return;
              }
              const index = draft.findIndex(
                (projectRiskItr) => projectRiskItr.id === projectRisk.id
              );
              if (index !== -1) {
                draft[index].comments?.push({
                  comment: projectRiskComment.comment,
                  date_created: new Date().toISOString(),
                  mentions: projectRiskComment.mentions,
                  user: projectRiskComment.user,
                });
              }
            }
          )
        );
        try {
          await queryFulfilled;
        } catch {
          patchResult.undo();
        }
      },
      invalidatesTags: [{ type: "ProjectRisk", id: "LIST" }],
    }),
    submitProjectRiskFeedback: builder.mutation<
      ProjectRiskFeedback,
      ProjectRiskFeedback
    >({
      query: (projectRiskFeedback) => ({
        url: `/projectrisks/${projectRiskFeedback.project_risk}/feedback/`,
        method: "POST",
        body: projectRiskFeedback,
      }),
    }),
    updateProjectRiskComment: builder.mutation<
      ProjectRiskComment,
      ProjectRiskComment
    >({
      query: (projectRiskComment) => ({
        url: `/projectriskcomments/${projectRiskComment.id}/`,
        method: "PATCH",
        body: projectRiskComment,
      }),
      async onQueryStarted(projectRiskComment, { dispatch, queryFulfilled }) {
        const patchResult = dispatch(
          projectRiskApi.util.updateQueryData(
            "getProjectRisk",
            projectRiskComment.project_uuid
              ? {
                  projectUUID: projectRiskComment.project_uuid,
                  sort: projectRiskComment.sort,
                }
              : { projectUUID: "" },
            (draft) => {
              const projectRisk = projectRiskComment.parent;
              if (!projectRisk) {
                return;
              }
              const index = draft.findIndex(
                (projectRiskItr) => projectRiskItr.id === projectRisk.id
              );
              if (index === -1) {
                return;
              }
              const commentsIndex = draft[index]?.comments?.findIndex(
                (c) => c.id === projectRiskComment.id
              );
              const cleanIndex =
                commentsIndex === undefined ? -1 : commentsIndex;
              if (cleanIndex === -1) {
                return;
              }
              const comments = draft[index]?.comments;
              if (!comments) {
                return;
              }
              if (!comments[cleanIndex]) {
                return;
              }
              comments[cleanIndex] = projectRiskComment;
            }
          )
        );
        try {
          await queryFulfilled;
        } catch {
          patchResult.undo();
        }
      },
      invalidatesTags: [{ type: "ProjectRisk", id: "LIST" }],
    }),
    deleteProjectRiskComment: builder.mutation<
      ProjectRiskComment,
      ProjectRiskComment
    >({
      query: ({ id }) => ({
        url: `/projectriskcomments/${id}/`,
        method: "DELETE",
      }),
      // optimistic update
      async onQueryStarted(projectRiskComment, { dispatch, queryFulfilled }) {
        const deleteResult = dispatch(
          projectRiskApi.util.updateQueryData(
            "getProjectRisk",
            projectRiskComment.project_uuid
              ? {
                  projectUUID: projectRiskComment.project_uuid,
                  sort: projectRiskComment.sort,
                }
              : { projectUUID: "" },
            (draft) => {
              const index = draft.findIndex(
                (projectRisk) =>
                  projectRisk.id === projectRiskComment.project_risk_id
              );
              const commentsIndex =
                projectRiskComment?.parent?.comments?.findIndex(
                  (rc) => rc?.id === projectRiskComment?.id
                );
              const cleanIndex =
                commentsIndex === undefined ? -1 : commentsIndex;
              if (cleanIndex === -1) {
                return;
              }
              if (cleanIndex !== -1 && projectRiskComment?.parent?.comments) {
                if ((draft[index]?.comments?.length ?? 0) > 1) {
                  draft[index]?.comments?.splice(cleanIndex, 1);
                } else {
                  draft[index].comments = [];
                }
              }
              draft[index] = { ...draft[index] };
            }
          )
        );
        try {
          await queryFulfilled;
        } catch {
          deleteResult.undo();
        }
      },
      invalidatesTags: (result, error, projectRiskComment) => [
        { type: "ProjectRisk", id: projectRiskComment.id },
      ],
    }),
    createRiskList: builder.mutation<RiskList, CreateRiskList>({
      query: ({ name }) => ({
        url: `/risklists/`,
        method: "POST",
        body: { name },
      }),
      invalidatesTags: [{ type: "RiskLists", id: "LIST" }],
      async onQueryStarted({ name }, { dispatch, queryFulfilled }) {
        const tempId = Date.now();
        const patchResult = dispatch(
          projectRiskApi.util.updateQueryData(
            "getRiskLists",
            undefined,
            (draft: RiskListPatch[]) => {
              draft.push({
                id: tempId,
                name,
                created_at: new Date().toISOString(),
                updated_at: new Date().toISOString(),
              });
            }
          )
        );
        try {
          const { data } = await queryFulfilled;
          dispatch(
            projectRiskApi.util.updateQueryData(
              "getRiskLists",
              undefined,
              (draft) => {
                const index = draft.findIndex((list) => list.id === tempId);
                if (index !== -1) draft[index] = data;
              }
            )
          );
        } catch {
          patchResult.undo();
        }
      },
    }),

    updateRiskList: builder.mutation<RiskList, UpdateRiskList>({
      query: ({ id, name }) => ({
        url: `/risklists/${id}/`,
        method: "PATCH",
        body: { name },
      }),
      invalidatesTags: (result, error, { id }) => [{ type: "RiskLists", id }],
      async onQueryStarted({ id, name }, { dispatch, queryFulfilled }) {
        const patchResult = dispatch(
          projectRiskApi.util.updateQueryData(
            "getRiskLists",
            undefined,
            (draft) => {
              const riskList = draft.find((list) => list.id === id);
              if (riskList) {
                riskList.name = name;
                riskList.updated_at = new Date().toISOString();
              }
            }
          )
        );
        try {
          await queryFulfilled;
        } catch {
          patchResult.undo();
        }
      },
    }),

    deleteRiskList: builder.mutation<void, number>({
      query: (id) => ({
        url: `/risklists/${id}/`,
        method: "DELETE",
      }),
      invalidatesTags: (result, error, id) => [{ type: "RiskLists", id }],
      async onQueryStarted(id, { dispatch, queryFulfilled }) {
        const patchResult = dispatch(
          projectRiskApi.util.updateQueryData(
            "getRiskLists",
            undefined,
            (draft) => {
              const index = draft.findIndex((list) => list.id === id);
              if (index !== -1) draft.splice(index, 1);
            }
          )
        );
        try {
          await queryFulfilled;
        } catch {
          patchResult.undo();
        }
      },
    }),

    getRiskLists: builder.query<RiskList[], void>({
      query: () => "/risklists/",
      providesTags: (result) =>
        result
          ? [
              ...result.map(({ id }) => ({ type: "RiskLists" as const, id })),
              { type: "RiskLists", id: "LIST" },
            ]
          : [{ type: "RiskLists", id: "LIST" }],
    }),

    getRiskListById: builder.query<RiskList, number>({
      query: (id) => `/risklists/${id}/`,
      providesTags: (result, error, id) => [{ type: "RiskLists", id }],
    }),

    getRiskPipelineDefinitions: builder.query<CustomRisk[], string>({
      query: (risk_list_id) =>
        `/riskpipelinedefinitions/?risk_list_template_id=${risk_list_id}`,
      // eslint-disable-next-line
      transformResponse: (response: any[]): CustomRisk[] =>
        response.map(({ id, risk_name, query_text, risk_description }) => ({
          id,
          risk_name,
          query_text,
          risk_description,
        })),
      providesTags: (result, error, risk_list_id) =>
        result
          ? [
              ...result.map(({ id }) => ({ type: "Risks" as const, id })),
              { type: "Risks", id: "LIST" },
              { type: "Risks", id: risk_list_id },
            ]
          : [
              { type: "Risks", id: "LIST" },
              { type: "Risks", id: risk_list_id },
            ],
    }),

    createRiskPipelineDefinition: builder.mutation<
      CustomRisk,
      CreateCustomRisk
    >({
      query: ({
        risk_name,
        query_text,
        risk_description,
        risk_list_template_id,
      }) => ({
        url: `/riskpipelinedefinitions/`,
        method: "POST",
        body: {
          risk_name,
          query_text,
          risk_description,
          risk_list_template_id,
        },
      }),
      invalidatesTags: (result, error, { risk_list_template_id }) => [
        { type: "Risks", id: "LIST" },
        { type: "Risks", id: risk_list_template_id },
      ],
      async onQueryStarted(
        { risk_name, query_text, risk_description, risk_list_template_id },
        { dispatch, queryFulfilled }
      ) {
        const tempId = Date.now().toString();
        const patchResult = dispatch(
          projectRiskApi.util.updateQueryData(
            "getRiskPipelineDefinitions",
            risk_list_template_id,
            (draft) => {
              draft.unshift({
                id: tempId,
                risk_name,
                query_text,
                risk_description,
              });
            }
          )
        );
        try {
          await queryFulfilled;
        } catch {
          patchResult.undo();
        }
      },
    }),

    updateRiskPipelineDefinition: builder.mutation<
      CustomRisk,
      UpdateCustomRisk
    >({
      query: ({ id, ...updateFields }) => ({
        url: `/riskpipelinedefinitions/${id}/`,
        method: "PATCH",
        body: Object.fromEntries(
          Object.entries(updateFields).filter(
            // eslint-disable-next-line
            ([_, value]) => value !== undefined
          )
        ),
      }),
      invalidatesTags: (result, error, { id, risk_list_template_id }) => [
        { type: "Risks", id },
        { type: "Risks", id: risk_list_template_id },
      ],
      async onQueryStarted(
        { id, risk_name, query_text, risk_list_template_id },
        { dispatch, queryFulfilled }
      ) {
        const patchResult = dispatch(
          projectRiskApi.util.updateQueryData(
            "getRiskPipelineDefinitions",
            risk_list_template_id,
            (draft) => {
              const riskToUpdate = draft.find((risk) => risk.id === id);
              if (riskToUpdate) {
                if (risk_name) {
                  riskToUpdate.risk_name = risk_name;
                }
                if (query_text) {
                  riskToUpdate.query_text = query_text;
                }
              }
            }
          )
        );
        try {
          const { data } = await queryFulfilled;
          dispatch(
            projectRiskApi.util.updateQueryData(
              "getRiskPipelineDefinitions",
              risk_list_template_id,
              (draft) => {
                const index = draft.findIndex((risk) => risk.id === id);
                if (index !== -1) draft[index] = data;
              }
            )
          );
        } catch {
          patchResult.undo();
        }
      },
    }),

    deleteRiskPipelineDefinition: builder.mutation<
      void,
      { id: string; risk_list_template_id: string }
    >({
      query: ({ id }) => ({
        url: `/riskpipelinedefinitions/${id}/`,
        method: "DELETE",
      }),
      invalidatesTags: (result, error, { id, risk_list_template_id }) => [
        { type: "Risks", id },
        { type: "Risks", id: risk_list_template_id },
      ],
      async onQueryStarted(
        { id, risk_list_template_id },
        { dispatch, queryFulfilled }
      ) {
        const patchResult = dispatch(
          projectRiskApi.util.updateQueryData(
            "getRiskPipelineDefinitions",
            risk_list_template_id,
            (draft) => {
              const index = draft.findIndex((risk) => risk.id === id);
              if (index !== -1) draft.splice(index, 1);
            }
          )
        );
        try {
          await queryFulfilled;
        } catch {
          patchResult.undo();
        }
      },
    }),

    runRiskListReview: builder.mutation<void, RunRiskListReviewArgs>({
      query: (body) => ({
        url: "/risklists/run_review/",
        method: "POST",
        body,
      }),
    }),
  }),
});

export const {
  useGetProjectRiskQuery,
  useGetProjectRiskStatsQuery,
  useGetProjectRisksProcessingStatusQuery,
  useCreateProjectRiskCommentMutation,
  useUpdateProjectRiskCommentMutation,
  useDeleteProjectRiskCommentMutation,
  useUpdateProjectRiskMutation,
  useSubmitProjectRiskFeedbackMutation,
  useGetProjectRiskStatusStatsQuery,
  useCreateRiskListMutation,
  useUpdateRiskListMutation,
  useDeleteRiskListMutation,
  useGetRiskListsQuery,
  useGetRiskListByIdQuery,
  useCreateRiskPipelineDefinitionMutation,
  useUpdateRiskPipelineDefinitionMutation,
  useGetRiskPipelineDefinitionsQuery,
  useDeleteRiskPipelineDefinitionMutation,
  useRunRiskListReviewMutation,
} = projectRiskApi;
