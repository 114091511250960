import React from "react";
import { Outlet } from "react-router-dom";

import { ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";

import ModalController from "../components/modals/modal-controller";
import NavHeader from "../components/layout/nav-header";

import useValidateURL from "../hooks/use-validate-url";
import TermsAndConditions from "../components/terms/terms-and-conditions";

export default function Root() {
  useValidateURL();
  return (
    <>
      <div className={"flex h-screen flex-col"}>
        <NavHeader />
        <Outlet />
      </div>

      <ToastContainer />
      <ModalController />
      <TermsAndConditions />
    </>
  );
}
