import React, { useEffect, useState, createRef } from "react";
import {
  selectCurrentPage,
  selectTotalPages,
  setTotalPages,
} from "../redux/viewer-slice";
import { useDispatch, useSelector } from "react-redux";
import { selectCurrentDocument } from "../redux/application-slice";
import { ProjectDocumentMetadata } from "../shared/interfaces/project/document/document.interface";

interface PageCountProps {
  document?: ProjectDocumentMetadata;
}

const PageCount: React.FC<PageCountProps> = ({ document }) => {
  const totalPages = useSelector(selectTotalPages);
  const currentDocument = useSelector(selectCurrentDocument);
  const currentPage = useSelector(selectCurrentPage);
  const dispatch = useDispatch();

  const [page, setPage] = useState(currentPage);
  const ref = createRef<HTMLInputElement>();

  useEffect(() => {
    setPage(currentPage);
  }, [currentPage]);

  useEffect(() => {
    dispatch(setTotalPages(1));
  }, [currentDocument, dispatch]);

  const onPageChange = (event) => {
    setPage(event.target.value);
  };

  return (
    <div
      className={
        "relative ml-4 flex items-center gap-2 rounded-lg border-2 border-[#ffffff00] bg-[#f1f5fa] px-2 py-1 shadow-sm"
      }
    >
      <div>
        <input
          disabled={true}
          ref={ref}
          max={totalPages}
          min={1}
          onChange={onPageChange}
          className={
            "cursor-select w-8 rounded-lg border-0 border-2 border-[#ffffff00] bg-[#ffffff00] p-0 text-center opacity-75 focus:bg-none focus:outline-none active:!border-0"
          }
          type="text"
          value={page}
        />
      </div>
      <div className="whitespace-nowrap">/ {totalPages}</div>
    </div>
  );
};

export default PageCount;
