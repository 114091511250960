import { ExclamationTriangleIcon } from "@heroicons/react/24/solid";
import React from "react";

const ErrorMessage = () => {
  return (
    <div className="flex h-full flex-col text-2xl items-center justify-center border p-3">
      <div>
        <ExclamationTriangleIcon className="h-24 w-24 mb-4 text-amber-600" />
      </div>
      <p className="font-bold text-3xl mb-6">Error Loading Page</p>
      <p>Please refresh the page and try again.</p>
    </div>
  );
};

export default ErrorMessage;
