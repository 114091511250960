import React, { useCallback, useMemo } from "react";
import {
  ChatHistory,
  ChatSessionMessage,
} from "../../../shared/interfaces/chat/chat-history.interface";
import AIChatSourceMessageButtonV2 from "./ai-chat-message-sources-v2";
import AIChatGroupedSource from "./ai-chat-grouped-source";
import AiChatSource from "./ai-chat-source";
import AIChatFeedbackButton from "./ai-chat-feedback-button";
import { ProjectDocumentMetadata } from "../../../shared/interfaces/project/document/document.interface";
import _ from "lodash";
import { HandThumbUpIcon } from "@heroicons/react/24/solid";
import { HandThumbDownIcon } from "@heroicons/react/24/solid";
import { AIChatConflict, AIChatConflictPreprocessed } from "./ai-chat-conflict";

interface AIChatMessageProps {
  chatHistoryMessage: ChatSessionMessage;
  index: number;
  selectedChatHistory: ChatHistory | null;
  documents: ProjectDocumentMetadata[];
  sessionUuid: string;
  query: string;
  writing: boolean;
  isLast: boolean;
  messageId: string | null;
}

const AIChatMessage: React.FC<AIChatMessageProps> = ({
  chatHistoryMessage,
  index,
  selectedChatHistory,
  documents,
  sessionUuid,
  query,
  writing,
  isLast,
  messageId,
}) => {
  const source = useMemo(() => {
    return chatHistoryMessage.origin === "USER" ? "user" : "system";
  }, [chatHistoryMessage]);

  const isMessageComplete = useCallback(() => {
    if (!isLast) {
      return true;
    }
    return !writing;
  }, [writing, isLast]);

  return (
    <div className="relative flex items-start pb-3 pr-2 pl-1">
      <div
        className={`relative flex w-full ${
          source === "user" ? "justify-end" : "justify-start"
        }`}
      >
        <div className="relative w-10/12 text-left rounded-lg bg-white">
          <div
            className={`whitespace-pre-line p-3 text-sm ${
              source === "user"
                ? "rounded bg-blue-200 text-left"
                : "rounded bg-white text-left"
            }`}
          >
            <AIChatSourceMessageButtonV2
              chatHistoryMessage={chatHistoryMessage}
            />
            {selectedChatHistory &&
              selectedChatHistory?.messages?.[index] &&
              selectedChatHistory?.messages?.every(
                (m) => !m.message_sources?.find((s) => s.text)
              ) &&
              (chatHistoryMessage.message_sources ?? [])?.length > 0 && (
                <div
                  className={
                    "mt-1 flex grow-0 flex-col items-end gap-1 border-t pt-1 text-xs"
                  }
                >
                  {chatHistoryMessage.message_sources &&
                    Object.keys(
                      _.groupBy(
                        chatHistoryMessage.message_sources,
                        "source_document_id"
                      )
                    ).map((key) => {
                      const groupedSource = _.groupBy(
                        chatHistoryMessage.message_sources,
                        "source_document_id"
                      )[key];
                      return (
                        <div key={`message_source${key}`} className="w-full">
                          <div className="overflow-hidden text-ellipsis whitespace-nowrap py-0.5">
                            {
                              documents?.find((item) => item.uuid === key)
                                ?.title
                            }
                          </div>
                          <div className="flex flex-wrap items-center gap-1 py-0.5">
                            <div>Pages:</div>
                            {groupedSource
                              .sort((a, b) => a.source - b.source)
                              .map((source, index) => (
                                <AIChatGroupedSource
                                  key={`page_source_${source}`}
                                  source={source}
                                  index={index}
                                  groupedSource={groupedSource}
                                />
                              ))}
                          </div>
                        </div>
                      );
                    })}
                </div>
              )}
          </div>
          {source !== "user" &&
            (chatHistoryMessage.message_sources ?? [])?.length > 0 && (
              <AiChatSource
                chatMessage={chatHistoryMessage}
                documents={documents ?? []}
              />
            )}
          {source !== "user" ? (
            chatHistoryMessage.conflict ? (
              <AIChatConflictPreprocessed
                conflict={chatHistoryMessage.conflict}
              />
            ) : (
              <AIChatConflict
                query={query}
                chatSessionMessage={chatHistoryMessage}
              />
            )
          ) : null}
        </div>

        <div>
          {source !== "user" && isMessageComplete() && (
            <div className="flex flex-col space-y-2 p-2">
              <AIChatFeedbackButton
                message={chatHistoryMessage.message}
                type="Good"
                sessionUuid={sessionUuid}
                messageId={messageId}
                query={query}
                icon={<HandThumbUpIcon className="h-5 w-5" />}
              />
              <AIChatFeedbackButton
                message={chatHistoryMessage.message}
                type="Bad"
                sessionUuid={sessionUuid}
                messageId={messageId}
                query={query}
                icon={<HandThumbDownIcon className="h-5 w-5" />}
              />
            </div>
          )}
        </div>
      </div>
    </div>
  );
};

export default AIChatMessage;
