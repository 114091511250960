import "./App.css";

import React, { useEffect } from "react";

// PDF Viewer
// import { Worker } from "@react-pdf-viewer/core";
// import { Viewer } from "@react-pdf-viewer/core";
import "@react-pdf-viewer/core/lib/styles/index.css";

// Mantine
import "@mantine/core/styles.css";
import { MantineProvider } from "@mantine/core";

import Router from "./routes/router";

// Logging
import LogRocket from "logrocket";

// Sentry
import * as Sentry from "@sentry/react";
import { WebsocketProvider } from "./contexts/websocket-context";
import { posthog } from "posthog-js";
import { useParams } from "react-router-dom";
const FallbackComponent = () => (
  <div>An error has occurred, please refresh the page.</div>
);

const App = () => {
  const { openChatSessionId } = useParams();

  LogRocket.init("jsv32f/provision-open-chat");

  useEffect(() => {
    if (openChatSessionId) {
      posthog?.identify(openChatSessionId, {
        sessionId: openChatSessionId,
      });
    }
  }, [openChatSessionId]);

  return (
    <MantineProvider>
      <WebsocketProvider>
        <div style={{ width: "100%", height: "100vh" }}>
          <Sentry.ErrorBoundary fallback={FallbackComponent}>
            <Router />
          </Sentry.ErrorBoundary>
        </div>
      </WebsocketProvider>
    </MantineProvider>
  );
};

export default App;
