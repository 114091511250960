// React and Redux
import React, { useEffect, useMemo, useState } from "react";
import { useDispatch, useSelector } from "react-redux";

// Components
import DocumentSidebar from "./document-sidebar";
import WorkflowSidebar from "./workflow-sidebar";
import DocumentViewerSidebar from "./document-viewer-sidebar";

// Hooks
import useWindowDimensions from "../hooks/use-window-dimensions";
import { useTabWidth } from "../hooks/use-tab-width";
import { useOnlineStatus } from "../hooks/use-online-status";

// Redux Selectors
import { selectDocumentViewerSidebarOpen } from "../redux/viewer-slice";
import {
  selectApplicationSidebarOpen,
  selectCurrentProject,
} from "../redux/application-slice";

// UI Components
import { Tooltip as MantineTooltip, Overlay } from "@mantine/core";
import { Tooltip } from "react-tooltip";
import { toast } from "react-toastify";

/**
 * Home is the main layout component that manages the document and workflow sidebars.
 * It handles:
 * - Responsive sidebar sizing and positioning
 * - Online/offline status notifications
 * - Resizable panels via drag handles
 * - Conditional rendering of sidebars based on application state
 */
const Home: React.FC = () => {
  const { width } = useWindowDimensions();
  const documentViewerSidebarOpen = useSelector(
    selectDocumentViewerSidebarOpen
  );
  const applicationSidebarOpen = useSelector(selectApplicationSidebarOpen);
  const { tabWidth } = useTabWidth();
  const online = useOnlineStatus();
  const [overlayOpacity, setOverlayOpacity] = useState(0.1);
  const [overlayBlur, setOverlayBlur] = useState(10);

  useEffect(() => {
    if (!online) {
      toast.error("You are offline. Check your network connection.", {
        toastId: "offline",
        autoClose: false,
        closeOnClick: false,
        draggable: false,
        hideProgressBar: true,
        pauseOnHover: false,
        position: "top-right",
        closeButton: false,
        className: "bg-gray-500 text-gray-100",
      });
    } else {
      toast.dismiss("offline");
    }
  }, [online]);

  const currentProject = useSelector(selectCurrentProject);
  const showOverlay = useMemo(() => {
    if (currentProject) {
      return currentProject.uuid === null;
    }
    return true;
  }, [currentProject]);

  useEffect(() => {
    if (showOverlay) {
      setOverlayOpacity(0.1);
      setOverlayBlur(10);
    } else {
      setOverlayOpacity(0);
      setOverlayBlur(0);
    }
  }, [showOverlay]);

  return (
    <div
      className="flex h-full w-full overflow-hidden"
      style={{
        maxHeight: "calc(100vh)",
      }}
    >
      {showOverlay ? (
        <Overlay
          color="#f1c40f"
          backgroundOpacity={overlayOpacity}
          blur={overlayBlur}
          zIndex={1000}
        />
      ) : null}
      {applicationSidebarOpen !== "right" && (
        <div
          className={`flex h-full w-2/3 flex-1 border border-t-0 border-r-[#d1d5db]`}
        >
          <DocumentSidebar width={"100%"} />
        </div>
      )}

      <div className="relative flex">
        {documentViewerSidebarOpen && <DocumentViewerSidebar />}
      </div>
      {applicationSidebarOpen !== "left" && (
        <div className={`w-1/3 overflow-hidden`}>
          <WorkflowSidebar width={"100%"} />
        </div>
      )}
      <Tooltip id={"document-title-single-tooltip"} style={{ zIndex: 100 }} />
      <Tooltip id={"document-title-tooltip"} style={{ zIndex: 100 }} />
    </div>
  );
};

export default Home;
