import React, { Fragment, useCallback } from "react";
import { Dialog, Transition } from "@headlessui/react";
import { useDispatch, useSelector } from "react-redux";
import {
  setModal,
  selectIsBookAMeetingModalUnclosable,
  selectModal,
} from "../../redux/application-slice";
import { ArrowLeftIcon } from "@heroicons/react/24/outline";

interface BookAMeetingModalProps {
  open: boolean;
  fromTerms?: boolean;
  onBack?: () => void;
}

function BookAMeetingModal({ open }: BookAMeetingModalProps) {
  const dispatch = useDispatch();
  const isUnclosable = useSelector(selectIsBookAMeetingModalUnclosable);
  const modalProps = useSelector(selectModal);
  const fromTerms = modalProps?.props?.fromTerms;
  const onBackCallback = modalProps?.props?.onBack;

  const onClose = useCallback(() => {
    if (isUnclosable || fromTerms) {
      return; // Prevent closing if unclosable or came from terms
    }
    dispatch(setModal(null));
  }, [dispatch, isUnclosable, fromTerms]);

  const onBack = useCallback(() => {
    dispatch(setModal(null));
    if (onBackCallback) {
      onBackCallback();
    }
  }, [dispatch, onBackCallback]);

  const dialogContent = (
    <Dialog.Panel className="relative transform overflow-hidden rounded-lg bg-white text-left shadow-xl transition-all sm:my-8 sm:w-full sm:max-w-4xl">
      {fromTerms && (
        <div className="absolute top-4 left-4 z-10">
          <button
            onClick={onBack}
            className="inline-flex items-center rounded-md bg-white px-3 py-2 text-sm font-semibold text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 hover:bg-gray-50"
          >
            <ArrowLeftIcon className="h-4 w-4 mr-2" />
            Back to Terms & Conditions
          </button>
        </div>
      )}
      <div className="h-[850px] w-full flex justify-center items-center">
        <iframe
          src="https://meetings.hubspot.com/nicholas-eto/demo-clone"
          width="100%"
          height="100%"
          frameBorder="0"
          title="Book a Meeting"
        ></iframe>
      </div>
    </Dialog.Panel>
  );

  return (
    <Transition.Root show={open} as={Fragment}>
      <Dialog
        as="div"
        className="relative z-50"
        onClose={onClose}
        static={isUnclosable || fromTerms}
      >
        <Transition.Child
          as={Fragment}
          enter="ease-out duration-300"
          enterFrom="opacity-0"
          enterTo="opacity-100"
          leave="ease-in duration-200"
          leaveFrom="opacity-100"
          leaveTo="opacity-0"
        >
          <div className="fixed inset-0 bg-gray-500 bg-opacity-75 transition-opacity" />
        </Transition.Child>

        <div className="fixed inset-0 z-10 overflow-y-auto">
          <div className="flex min-h-full items-end justify-center p-4 text-center sm:items-center sm:p-0">
            <Transition.Child
              as={Fragment}
              enter="ease-out duration-300"
              enterFrom="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
              enterTo="opacity-100 translate-y-0 sm:scale-100"
              leave="ease-in duration-200"
              leaveFrom="opacity-100 translate-y-0 sm:scale-100"
              leaveTo="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
            >
              {dialogContent}
            </Transition.Child>
          </div>
        </div>
      </Dialog>
    </Transition.Root>
  );
}

export default BookAMeetingModal;
