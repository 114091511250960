import React, { useCallback, useEffect, useRef, useState } from "react";
import "react-tooltip/dist/react-tooltip.css";
import { useDispatch, useSelector } from "react-redux";
import {
  selectCurrentDocument,
  selectSourceHighlight,
  setCurrentHighlight,
} from "../../redux/application-slice";
import {
  selectCurrentPage,
  selectZoomLevel,
  setCurrentPage,
  setTotalPages,
  setZoomLevel,
} from "../../redux/viewer-slice";
import ViewerShortcuts from "../viewer/viewer-shortcuts";
import DocumentViewerOptionsHeader from "./components/document-viewer-header-options";
import DocumentViewerHeaderMisc from "./components/document-viewer-header-misc";
import { useDocumentViewer } from "../../hooks/use-document-viewer";

import { Viewer, Worker, ZoomEvent } from "@react-pdf-viewer/core";
import { pageNavigationPlugin } from "@react-pdf-viewer/page-navigation";
import { zoomPlugin } from "@react-pdf-viewer/zoom";
import { OnHighlightKeyword, searchPlugin } from "@react-pdf-viewer/search";
import { toolbarPlugin, ToolbarSlot } from "@react-pdf-viewer/toolbar";
import {
  highlightPlugin,
  RenderHighlightsProps,
  Trigger,
} from "@react-pdf-viewer/highlight";

import "@react-pdf-viewer/core/lib/styles/index.css";
import "@react-pdf-viewer/default-layout/lib/styles/index.css";

import { Text } from "@mantine/core";
import { pageUtils } from "../../utils/page-utils";

// TODO nikhi broke the Skeleton Loading state view
// import DocumentViewerSkeleton from './document-viewer-skeleton'

function DocumentViewer() {
  const [documentLoaded, setDocumentLoaded] = useState(false);
  const scrollView = useRef<HTMLDivElement | null>(null);
  const viewer = useRef<HTMLDivElement | null>(null);
  useDocumentViewer(viewer, scrollView);

  const currentPage = useSelector(selectCurrentPage);
  const selectedDocument = useSelector(selectCurrentDocument);
  const currentHighlight = useSelector(selectSourceHighlight);

  // This setup allows us to sync the current page with Redux while avoiding an infinite loop.
  // When scrolling changes the page, we set isScrolling=true to prevent the useEffect from
  // jumping to that page again. This prevents a feedback loop where:
  // scroll -> redux update -> useEffect jump -> scroll -> redux update -> etc
  // which would make scrolling very choppy. Instead, the useEffect only jumps to pages
  // when the page change came from outside (like clicking a source).
  const pageNavigationPluginInstance = pageNavigationPlugin();
  const dispatch = useDispatch();
  // Add this ref to track if the page change came from scrolling
  const isScrolling = useRef(false);

  const handlePageChange = (e: {
    currentPage: number;
    doc: { numPages: number };
  }) => {
    // Convert from 0-based PDF viewer page to 1-based UI page
    const displayPage = pageUtils.toDisplayPage(e.currentPage);

    isScrolling.current = true; // Mark that this change came from scrolling
    dispatch(setCurrentPage(displayPage));
    dispatch(setTotalPages(e.doc.numPages));

    // Reset the flag after a short delay
    setTimeout(() => {
      isScrolling.current = false;
    }, 100);
  };

  const onChangeDocument = useCallback(() => {
    setDocumentLoaded(false);
  }, [selectedDocument]);

  useEffect(() => {
    onChangeDocument();
  }, [onChangeDocument]);

  // Only jump to page if the change didn't come from scrolling
  useEffect(() => {
    if (!documentLoaded) {
      return;
    }
    const jumpToPage = pageNavigationPluginInstance.jumpToPage;
    if (jumpToPage && currentPage > 0 && !isScrolling.current) {
      jumpToPage(currentPage - 1);
    }
  }, [currentPage, pageNavigationPluginInstance, documentLoaded]);

  // Zoom level
  const scale = useSelector(selectZoomLevel);
  const zoomPluginInstance = zoomPlugin();
  const { zoomTo } = zoomPluginInstance;

  useEffect(() => {
    if (zoomTo) {
      zoomTo(scale);
    }
  }, [scale, zoomTo]);

  const handleZoomChange = (e: ZoomEvent) => {
    dispatch(setZoomLevel(e.scale));
  };

  // Search
  const searchPluginInstance = searchPlugin({
    onHighlightKeyword: (props: OnHighlightKeyword) => {
      props.highlightEle.style.outline = "1px dashed blue";
      props.highlightEle.style.backgroundColor = "rgba(0, 0, 0, .1)";
    },
  });
  const toolbarPluginInstance = toolbarPlugin();

  const highlightPluginInstance = highlightPlugin({
    renderHighlights: (props: RenderHighlightsProps) => {
      if (!currentHighlight || !currentHighlight.source_quads) {
        return null;
      }

      return (
        <div>
          {currentHighlight.source_quads.map((quad, quadIndex) => {
            // Convert 1-based page numbers to 0-based for the plugin
            if (parseInt(currentHighlight.source) !== props.pageIndex + 1) {
              return null;
            }

            // Convert the quads from the BE to percentages for react-pdf-viewer
            const highlightArea = {
              height: Math.abs(quad.y1 - quad.y4) * 100,
              left: Math.min(quad.x1, quad.x4) * 100,
              top: Math.min(quad.y1, quad.y4) * 100,
              width: Math.abs(quad.x2 - quad.x1) * 100,
              pageIndex: props.pageIndex,
            };

            return (
              <div
                key={`${currentHighlight.id}-${quadIndex}`}
                style={{
                  ...props.getCssProperties(highlightArea, props.rotation),
                  background: "yellow",
                  opacity: 0.4,
                  position: "absolute",
                  pointerEvents: "none",
                  border: "1px solid rgba(255, 255, 0, 0.5)",
                }}
                onClick={(e) => {
                  e.stopPropagation();
                }}
              />
            );
          })}
        </div>
      );
    },
    trigger: Trigger.None,
  });

  const { Toolbar } = toolbarPluginInstance;

  const ViewerLoader = ({ percentages }: { percentages: number }) => (
    <div
      style={{
        width: "100%",
        height: "100%",
        backgroundColor: "#e5e7eb",
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
      }}
    >
      <div className="text-lg font-medium">
        <Text size="md" c="dimmed">
          Loading {Math.round(percentages)}%
        </Text>
      </div>
    </div>
  );

  const onDocumentLoad = useCallback(() => {
    setDocumentLoaded(true);
    if (currentHighlight?.document !== selectedDocument?.id) {
      dispatch(setCurrentHighlight(null));
    } else if (!currentHighlight) {
      dispatch(setCurrentPage(1));
    }
  }, [selectedDocument, currentHighlight, dispatch]);

  return (
    <div
      className="flex h-full items-stretch overflow-hidden"
      style={{ maxWidth: "100vw" }}
    >
      <div className={"bg-white-100 flex min-w-0 flex-1 flex-grow flex-col"}>
        <div
          className={
            "flex h-12 flex-shrink-0 items-center justify-between border-b border-gray-300 bg-[#edf0f5] px-4 pr-1"
          }
        >
          <DocumentViewerHeaderMisc />
          <DocumentViewerOptionsHeader />
        </div>
        <Worker workerUrl="https://unpkg.com/pdfjs-dist@3.4.120/build/pdf.worker.min.js">
          <div
            style={{
              height: "99vh",
              width: "100%",
              backgroundColor: "#e5e7eb",
            }}
          >
            <div
              className="rpv-core__viewer"
              style={{
                display: "flex",
                height: "100%",
                position: "relative",
              }}
            >
              <div
                style={{
                  alignItems: "center",
                  backgroundColor: "#eeeeee",
                  borderRadius: "2px",
                  top: "14px",
                  display: "flex",
                  left: "34px",
                  padding: "4px",
                  position: "absolute",
                  transform: "translate(-50%, 0)",
                  zIndex: 30,
                }}
              >
                <Toolbar>
                  {(props: ToolbarSlot) => {
                    const { ShowSearchPopover } = props;
                    return (
                      <>
                        <div style={{ padding: "0px 2px" }}>
                          <ShowSearchPopover />
                        </div>
                      </>
                    );
                  }}
                </Toolbar>
              </div>
              <div
                style={{
                  flex: 1,
                  overflow: "hidden",
                  marginBottom: "5rem",
                }}
              >
                {selectedDocument !== null ? (
                  <Viewer
                    defaultScale={scale}
                    plugins={[
                      pageNavigationPluginInstance,
                      zoomPluginInstance,
                      searchPluginInstance,
                      toolbarPluginInstance,
                      highlightPluginInstance,
                    ]}
                    onDocumentLoad={onDocumentLoad}
                    onPageChange={handlePageChange}
                    onZoom={handleZoomChange}
                    fileUrl={selectedDocument.optimized_file}
                    pageLayout={{
                      buildPageStyles: () => ({
                        backgroundColor: "#e5e7eb",
                      }),
                    }}
                    renderLoader={(percentages) => (
                      <ViewerLoader percentages={percentages} />
                    )}
                  />
                ) : (
                  <div className="flex h-full items-center justify-center">
                    <Text c="dimmed">Connecting...</Text>
                  </div>
                )}
              </div>
            </div>
          </div>
        </Worker>
      </div>
      <ViewerShortcuts />
    </div>
  );
}

export default DocumentViewer;
