import React, {
  useCallback,
  useContext,
  useEffect,
  useMemo,
  useRef,
  useState,
} from "react";
import * as Sentry from "@sentry/react";

import { WebsocketContext } from "../../../contexts/websocket-context";
import TextareaAutosize from "react-textarea-autosize";
import {
  ClipboardDocumentIcon,
  EllipsisHorizontalIcon,
  PaperAirplaneIcon,
  UserCircleIcon,
} from "@heroicons/react/24/outline";
import { useDispatch, useSelector } from "react-redux";
import {
  selectCredits,
  selectCurrentDocument,
  selectPastMessages,
  setCredits,
  setIsBookAMeetingModalUnclosable,
  setModal,
} from "../../../redux/application-slice";
import { useParams, useSearchParams } from "react-router-dom";
import {
  ChatHistory,
  ChatMessage,
  ChatSessionMessage,
} from "../../../shared/interfaces/chat/chat-history.interface";
import { toast } from "react-toastify";
import { usePostHog } from "posthog-js/react";
import { POSTHOG } from "../../../utils/posthog-constants";
import { Project } from "../../../shared/interfaces/project/project.interface";
import AIChatSuggestion from "./ai-chat-suggestion";
import AIChatMessage from "./ai-chat-message";
import { ProjectDocumentMetadata } from "../../../shared/interfaces/project/document/document.interface";
import DocumentListboxMulti from "../../document-listbox/document-listbox-multi";
import {
  selectExplainQuery,
  setExplainQuery,
} from "../../../redux/viewer-slice";
import { HeaderContainer } from "../../workflow-sidebar";
import { Loader, Tooltip } from "@mantine/core";
import { MODAL_TYPES } from "../../modals/modal-controller";
import { useGetProjectByIdQuery } from "../../../redux/api-slice";
import { skipToken } from "@reduxjs/toolkit/query";
import { ChatBubbleLeftIcon } from "@heroicons/react/24/outline";

export interface ChatSuggestion {
  topic: string;
  suggestion: string;
}

const suggestions: ChatSuggestion[] = [
  {
    topic: "Hazards",
    suggestion:
      "What are the requirements for contaminants or hazardous materials present on site that the contractor should be aware of?",
  },
  {
    topic: "Responsibilities",
    suggestion:
      "Is the owner responsible for providing permits or providing any scope of services?",
  },
];

function AiChat() {
  const [chatInput, setChatInput] = useState<string>("");
  const websocketContext = useContext(WebsocketContext);

  const {
    onMessage,
    messages,
    thinking,
    connected,
    setMessages,
    setSessionUuid,
    sessionUuid,
    writing,
    sendExplainRequest,
    setOpenChatSessionId,
  } = websocketContext;

  const dispatch = useDispatch();
  const explainQuery = useSelector(selectExplainQuery);

  // Can't use Redux for this because we need the loading state to delay the validation check/render on Line 511
  const { openChatSessionId } = useParams();
  useEffect(() => {
    setOpenChatSessionId(openChatSessionId ?? null);
  }, [openChatSessionId, setOpenChatSessionId]);
  const { data: currentProject, isLoading: currentProjectIsLoading } =
    useGetProjectByIdQuery(openChatSessionId ?? skipToken);

  // TODO types
  const documents = currentProject?.project.documents;
  const currentDocument = useSelector(selectCurrentDocument);

  const [projectState, setProjectState] = useState<Project | null>(
    currentProject
  );
  const [isInChatMode, setIsInChatMode] = useState(true);
  const endRef = useRef<HTMLDivElement | null>(null);
  const chatRef = useRef<HTMLTextAreaElement | null>(null);
  const [selectedChatHistory, setSelectedChatHistory] =
    useState<ChatHistory | null>(null);
  const [selectedDocuments, setSelectedDocuments] = useState<
    ProjectDocumentMetadata[]
  >([]);
  const [thinkingTimer, setThinkingTimer] = useState<number | null>(null);

  const [searchParams, setSearchParams] = useSearchParams();
  const posthog = usePostHog();

  const onNewChat = useCallback(() => {
    searchParams.delete("chat_history_id");
    setSearchParams(searchParams);
    setTimeout(() => {
      chatRef?.current?.focus();
    }, 50);
    setIsInChatMode(true);
    setSelectedChatHistory(null);
    setMessages({});
    setSessionUuid(null);
  }, [searchParams, setSearchParams, setMessages, setSessionUuid]);

  const resetChatState = useCallback(() => {
    if (!projectState?.id || !currentProject?.id) {
      return;
    }
    if (projectState.id !== currentProject?.id) {
      setIsInChatMode(false);
      setMessages({});
      setSessionUuid(null);
      setChatInput("");
      setProjectState(currentProject);
    }
  }, [currentProject, projectState?.id, setMessages, setSessionUuid]);

  const setChatHistorySearchParam = useCallback(() => {
    if (!sessionUuid || searchParams.get("chat_history_id") === sessionUuid) {
      return;
    }
    searchParams.set("chat_history_id", sessionUuid);
    setSearchParams(searchParams);
  }, [searchParams, sessionUuid, setSearchParams]);

  useEffect(setChatHistorySearchParam, [setChatHistorySearchParam]);
  useEffect(resetChatState, [resetChatState]);

  useEffect(() => {
    if (messages && endRef.current) {
      endRef.current?.scrollIntoView({
        behavior: "smooth",
        block: "end",
      });
    }
  }, [messages]);

  useEffect(() => {
    if (thinking) {
      setThinkingTimer(0);
    } else {
      setThinkingTimer(null);
    }
  }, [thinking]);

  useEffect(() => {
    if (thinkingTimer === null) return;

    if (thinkingTimer === 5) {
      posthog?.capture(POSTHOG.chat_slow);
      Sentry.captureMessage("Chat said 'Things are taking longer than usual'");
    }

    const intervalId = setInterval(() => {
      setThinkingTimer(thinkingTimer + 1);
    }, 1000);

    return () => clearInterval(intervalId);
  }, [thinkingTimer, posthog]);

  const pastMessages = useSelector(selectPastMessages);

  const messagesToDisplay = useMemo(() => {
    // Convert past messages to ChatSessionMessage format
    const pastMessagesMapped = (pastMessages ?? []).map((msg) => ({
      message: msg.message,
      origin: msg.origin,
      date_created: msg.date_created,
      message_sources: msg.message_sources,
      id: msg.id,
      conflict: msg.conflict,
    }));

    // Map current session messages
    const currentMessagesMapped = Object.values(messages).map(
      (message: ChatMessage) => ({
        message: message.content,
        origin: message.source === "user" ? "USER" : "SYSTEM",
        date_created: new Date().toISOString(),
        message_sources: message.sources,
        filtered_documents: message?.filtered_documents,
        id: message.chat_session_message_id,
        conflict: message.conflict,
      })
    );

    // Combine and sort all messages
    return [...pastMessagesMapped, ...currentMessagesMapped].sort(
      (a, b) =>
        new Date(a?.date_created ?? "").getTime() -
        new Date(b?.date_created ?? "").getTime()
    );
  }, [pastMessages, messages]);

  const copyChatToClipboard = useCallback(async () => {
    let text = "";
    for (const message of messagesToDisplay) {
      if (message.origin === "USER") {
        text += `User: ${message.message}\n`;
      } else if (message.origin === "SYSTEM") {
        text += `Provision: ${message.message}\n`;
      }
    }
    await navigator.clipboard.writeText(text);
    toast.success("Chat copied to clipboard");
  }, [messagesToDisplay]);

  const availableDocuments = useMemo(() => {
    if (!documents) {
      return [];
    }
    return documents.filter(
      (document) =>
        document.job_status !== "PENDING" &&
        document.job_status !== "PROCESSING" &&
        document.job_status !== "ARCHIVED" &&
        document.job_status !== "UNARCHIVING"
    );
  }, [documents]);

  const archivedDocuments = useMemo(() => {
    if (!documents) {
      return [];
    }
    return documents.filter(
      (document) =>
        document.job_status === "ARCHIVED" ||
        document.job_status === "UNARCHIVING"
    );
  }, [documents]);

  const currentCredits = useSelector(selectCredits);

  useEffect(() => {
    if (currentCredits === 0) {
      const timeoutId = setTimeout(() => {
        dispatch(setIsBookAMeetingModalUnclosable(true));
        toast.error(
          "Your credits have been depleted. Please connect with us to extend your trial or see a full demo of the Provision platform."
        );
        dispatch(setModal({ modal: MODAL_TYPES.BOOK_A_MEETING }));
      }, 25000);

      return () => clearTimeout(timeoutId);
    }
  }, [currentCredits, dispatch]);

  const onSendMessage = useCallback(
    (message: string) => {
      onMessage(
        message,
        selectedDocuments || availableDocuments.length === documents?.length
          ? selectedDocuments
            ? selectedDocuments.map((d) => d.id)
            : []
          : availableDocuments?.map((d) => d.id)
      );
      posthog?.capture(POSTHOG.chat_sent_message, {
        chat_session_uuid: sessionUuid,
        project_uuid: currentProject?.uuid,
      });

      dispatch(setCredits(Math.max(0, (currentCredits ?? 0) - 1)));
    },
    [
      onMessage,
      selectedDocuments,
      availableDocuments,
      documents?.length,
      posthog,
      sessionUuid,
      currentProject?.uuid,
      dispatch,
      currentCredits,
    ]
  );

  useEffect(() => {
    if (!explainQuery || !currentProject?.uuid || !currentDocument?.uuid) {
      return;
    }
    onNewChat();
    sendExplainRequest(
      explainQuery,
      currentProject?.uuid ?? "",
      currentDocument?.uuid ?? ""
    );
    dispatch(setExplainQuery(null));
    setChatInput("");
  }, [
    currentDocument?.uuid,
    currentProject?.uuid,
    dispatch,
    explainQuery,
    onSendMessage,
    sendExplainRequest,
    onNewChat,
  ]);

  const onClickSend = useCallback(() => {
    onSendMessage(chatInput);
    setChatInput("");
  }, [onSendMessage, chatInput]);

  const onCopyTranscriptClick = useCallback(() => {
    copyChatToClipboard();
  }, [copyChatToClipboard]);

  const onKeyDownChat = useCallback(
    (e) => {
      if (!isInChatMode) {
        e.stopPropagation();
        e.preventDefault();
        return;
      }
      if (e.key === "Enter") {
        e.stopPropagation();
        e.preventDefault();
        onSendMessage(chatInput);
        setChatInput("");
      }
    },
    [chatInput, isInChatMode, onSendMessage]
  );

  const onSetChatInput = useCallback((e) => {
    setChatInput(e.target.value);
  }, []);

  const onBookMeeting = useCallback(() => {
    posthog?.capture(POSTHOG.book_meeting_clicked, {
      source: "ai_chat",
      credits_remaining: currentCredits,
    });
    dispatch(setModal({ modal: MODAL_TYPES.BOOK_A_MEETING }));
  }, [dispatch, posthog, currentCredits]);

  const chatFooter = useMemo(() => {
    const textAreaDisabled = !connected || !isInChatMode || writing;

    return (
      <div className="mb-1 border-t border-gray-300 p-3 pb-1">
        <div className="mb-2 flex min-h-[36px] space-x-2">
          <button
            type="button"
            className="relative flex flex-shrink-0 min-w-0 bg-gray-50 border-2 flex-1 items-center justify-center rounded px-2 py-1.5 text-xs text-gray-600 hover:bg-gray-50"
            onClick={onBookMeeting}
          >
            <UserCircleIcon className="mr-2 h-4 w-4 flex-shrink-0" />
            <span className="truncate">Book a Call with Provision</span>
            <span
              className="absolute -top-1.5 -right-1.5 flex h-2 w-2 opacity-0"
              style={{ animation: `fadeInOutDot 15s infinite` }}
            >
              <span className="absolute inline-flex h-full w-full animate-ping rounded-full bg-sky-400 opacity-75"></span>
              <span className="relative inline-flex h-2 w-2 rounded-full bg-blue-500"></span>
            </span>
          </button>
          <button
            type="button"
            className="flex flex-shrink-0 min-w-0 flex-1 bg-gray-100 border-2 items-center justify-center rounded px-2 py-1.5 text-xs text-gray-600 hover:bg-gray-50"
            onClick={onCopyTranscriptClick}
          >
            <ClipboardDocumentIcon className="mr-2 h-4 w-4 flex-shrink-0" />
            <span className="truncate">Copy Transcript of Conversation</span>
          </button>
        </div>
        <div className="relative flex grow">
          <TextareaAutosize
            autoFocus
            ref={chatRef}
            disabled={textAreaDisabled}
            value={chatInput}
            onChange={onSetChatInput}
            onKeyDown={onKeyDownChat}
            minRows={1}
            placeholder={`${
              !connected
                ? "Connection lost. Please check your internet connection or try again later."
                : writing
                ? "Provision is Generating an Answer"
                : `Ask Provision a Question; ${
                    currentCredits ?? 0
                  } Messages Remaining`
            }`}
            className={`block w-full resize-none overflow-y-hidden hover:ring-2 hover:ring-inset hover:ring-blue-400 p-2 rounded-lg border-0 pr-12 text-gray-900 shadow ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-blue-600 sm:text-sm sm:leading-6 bg-white`}
          />
          <button
            className="absolute bottom-1.5 right-2 rounded-md p-1 text-gray-500 transition-colors disabled:text-gray-400 bg-white disabled:opacity-40"
            onClick={onClickSend}
          >
            <PaperAirplaneIcon className="h-5 w-5" />
          </button>
        </div>
        <div className={"py-1 pt-2 text-center text-xs text-gray-500"}>
          Provision can make mistakes. Consider checking important information.
        </div>
      </div>
    );
  }, [
    documents,
    messagesToDisplay.length,
    thinking,
    chatInput,
    connected,
    isInChatMode,
    onClickSend,
    onSetChatInput,
    writing,
    onKeyDownChat,
    onNewChat,
    onCopyTranscriptClick,
    availableDocuments.length,
  ]);

  // Validate against bad session IDs
  useEffect(() => {
    if (
      (!openChatSessionId || (openChatSessionId && currentProject === null)) &&
      !currentProjectIsLoading
    ) {
      toast.error(
        "Session ID is malformed. Please reach out to support@useprovision.com"
      );
      return;
    }
  }, [openChatSessionId, currentProject, currentProjectIsLoading]);

  useEffect(() => {
    if (messagesToDisplay.length > 0 && endRef.current) {
      const shouldUseSmooth =
        document.getElementById("chat-scroller")?.scrollTop > 0;
      setTimeout(() => {
        endRef.current?.scrollIntoView({
          behavior: shouldUseSmooth ? "smooth" : "auto",
          block: "end",
        });
      }, 100);
    }
  }, [messagesToDisplay, messages]);

  if (
    (!openChatSessionId || (openChatSessionId && currentProject === null)) &&
    !currentProjectIsLoading
  ) {
    return null;
  }

  return (
    <div className="flex h-full w-full min-w-0 flex-grow flex-col overflow-auto">
      <>
        <HeaderContainer>
          <Tooltip
            label={`This demo has a limited allowance for free messages. To increase your message limit please reach out to Live Chat.`}
            offset={12}
            multiline
            w="350px"
            styles={{
              tooltip: {
                textAlign: "center",
                padding: "12px",
                paddingX: "4px",
              },
            }}
            classNames={{ tooltip: "!text-[16px] font-medium" }}
          >
            <div className="flex items-center flex-shrink-0 mr-2 border border-gray-200 rounded-md">
              <span className="inline-flex items-center rounded bg-gray-50 p-2 text-sm py-1.5 text-gray-700 ring-1 ring-inset ring-gray-500/10">
                <ChatBubbleLeftIcon className="mr-2 h-4 w-4 mt-0.5" />
                {currentCredits} Messages Remaining
              </span>
            </div>
          </Tooltip>
          <div className="w-[350px]">
            <DocumentListboxMulti
              customClass="flex flex-1 items-center justify-start rounded px-2 py-1.5 text-xs text-gray-600 ring-1 ring-inset ring-gray-200 hover:bg-gray-100"
              documents={availableDocuments}
              customWidth="w-96"
              selectedDocuments={selectedDocuments}
              setSelectedDocuments={setSelectedDocuments}
            />
          </div>
        </HeaderContainer>
        <div className="w-full flex-auto overflow-auto" id="chat-scroller">
          {messagesToDisplay.length !== 0 && (
            <div className="p-2">
              {messagesToDisplay?.map(
                (message: ChatSessionMessage, index: number) => {
                  return (
                    <AIChatMessage
                      writing={writing}
                      key={`${message.message}${index}`}
                      chatHistoryMessage={message}
                      index={index}
                      selectedChatHistory={selectedChatHistory}
                      documents={documents ?? []}
                      sessionUuid={sessionUuid ?? ""}
                      isLast={messagesToDisplay.length - 1 === index}
                      query={
                        index > 0
                          ? messagesToDisplay[index - 1]?.message ?? ""
                          : ""
                      }
                      messageId={message.id}
                    />
                  );
                }
              )}
              {thinking && (
                <div className={"flex w-full justify-start px-2 pb-2 pt-1"}>
                  <div className={"self-end"}>
                    <div
                      className={"rounded border bg-white p-1 text-sm shadow"}
                    >
                      {(thinkingTimer ?? 0) <= 10 ? (
                        <EllipsisHorizontalIcon
                          width={30}
                          className={"animate-pulse"}
                        />
                      ) : (
                        <div className="animate-pulse">
                          Things are taking longer than usual...
                        </div>
                      )}
                    </div>
                  </div>
                </div>
              )}
              <div ref={endRef} id="chat-anchor" className="h-px" />
            </div>
          )}
        </div>
        <div className="flex-initial">
          {!thinking && messagesToDisplay.length === 0 && (
            <div className="space-y-2 px-3 pb-3">
              <div className="w-full gap-2 pb-2 no-underline">
                <button
                  className="relative text-gray-500 border-2 hover:bg-blue-100 underline hover:text-blue-600 no-underline bg-[#e5e7eb] px-4 py-2 w-full"
                  onClick={() =>
                    dispatch(setModal({ modal: MODAL_TYPES.LEARN_QUESTIONS }))
                  }
                >
                  <div className="flex items-center justify-center">
                    Learn how to ask questions with Provision
                    <span className="relative flex h-2 w-2 ml-2">
                      <span className="absolute inline-flex h-full w-full animate-ping rounded-full bg-sky-400 opacity-75"></span>
                      <span className="relative inline-flex h-2 w-2 rounded-full bg-blue-500"></span>
                    </span>
                  </div>
                </button>
              </div>
              <div className="flex flex-col gap-2">
                {suggestions?.map((suggestion) => (
                  <AIChatSuggestion
                    suggestion={suggestion}
                    key={suggestion.suggestion}
                    onSendMessage={onSendMessage}
                    setChatInput={setChatInput}
                  />
                ))}
              </div>
            </div>
          )}
          {archivedDocuments?.length ? (
            <div className="flex space-x-2 bg-gray-50 p-1 text-xs">
              <div>
                Some documents are archived and cannot be used. Chatting using{" "}
                {availableDocuments.length} / {documents?.length} total
                documents.
              </div>
            </div>
          ) : (
            availableDocuments.length !== documents?.length &&
            documents?.length && (
              <div className="mb-3 flex space-x-2 p-1 px-4 text-xs">
                <div>
                  <Loader size="xs" color="yellow" />
                </div>
                <div>
                  <span className="font-semibold">
                    Some documents are still processing.
                  </span>{" "}
                  Chatting using {availableDocuments.length} /{" "}
                  {documents?.length} total documents.
                </div>
              </div>
            )
          )}

          {chatFooter}
        </div>
      </>
    </div>
  );
}

export default AiChat;
