import React, { useCallback } from "react";
import { useNavigate, useParams, useSearchParams } from "react-router-dom";
import {
  selectCurrentProject,
  setCurrentHighlight,
} from "../../../redux/application-slice";
import { useDispatch, useSelector } from "react-redux";
import { v4 as uuidv4 } from "uuid";
import { ChatSessionMessageSource } from "../../../shared/interfaces/chat/chat-history.interface";
import {
  SelectedSource,
  setCurrentPage,
  setSelectedSources,
} from "../../../redux/viewer-slice";
import { usePostHog } from "posthog-js/react";
import { POSTHOG } from "../../../utils/posthog-constants";

interface AIChatSourceMessageButtonProps {
  chatSessionMessageSource: ChatSessionMessageSource;
  sources: ChatSessionMessageSource[];
  sourceIndex: number;
}

const AIChatSourceMessageButton: React.FC<AIChatSourceMessageButtonProps> = ({
  chatSessionMessageSource,
  sources,
  sourceIndex,
}) => {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const { openChatSessionId, documentId } = useParams();
  const [searchParams, setSearchParams] = useSearchParams();
  const posthog = usePostHog();
  const currentProject = useSelector(selectCurrentProject);

  const onSourceClick = useCallback(() => {
    // Only redirect if the project or document has changed
    const sourceDocumentUUID = currentProject?.project?.documents?.find(
      (document) => document.id === chatSessionMessageSource?.document
    )?.uuid;
    if (!sourceDocumentUUID) {
      return;
    }
    if (documentId !== sourceDocumentUUID) {
      navigate(`/${openChatSessionId}/chat/${sourceDocumentUUID}`);
    }

    const page = chatSessionMessageSource.source.toString();
    searchParams.set("page", page);
    setSearchParams(searchParams);
    dispatch(setCurrentPage(parseInt(page)));
    dispatch(setCurrentHighlight(chatSessionMessageSource));

    const selectedSources: SelectedSource[] = [];
    const distinctSources = sources.reduce<ChatSessionMessageSource[]>(
      (acc, citation) => {
        const document_segment_id = citation.document_segment;
        if (!acc.find((c) => c.document_segment === document_segment_id)) {
          acc.push(citation);
        }
        return acc;
      },
      []
    );
    for (const chatSource of distinctSources) {
      const highlightID = uuidv4();

      selectedSources.push({
        id: highlightID,
        page: parseInt(chatSource.source.toString()),
        quads: chatSource.source_quads,
        isPrimary: chatSource.id === chatSessionMessageSource.id,
        documentUUID: sourceDocumentUUID,
      });
    }
    dispatch(setSelectedSources(selectedSources));
    posthog?.capture(POSTHOG.chat_source_clicked, {
      source: sourceDocumentUUID,
      page: chatSessionMessageSource.source,
      project_uuid: openChatSessionId,
    });
  }, [
    dispatch,
    navigate,
    openChatSessionId,
    searchParams,
    setSearchParams,
    chatSessionMessageSource,
    sources,
    posthog,
  ]);

  return (
    <button
      style={{ all: "unset" }}
      onClick={onSourceClick}
      className="cursor-pointer"
    >
      <sup
        className={
          "mr-0.5 cursor-pointer rounded-full bg-yellow-300 px-1 w-3 h-3 text-center text-[0.55rem] text-black hover:bg-gray-500 hover:text-white"
        }
      >
        {sourceIndex + 1}
      </sup>
    </button>
  );
};
export default AIChatSourceMessageButton;
