import React, {
  useCallback,
  useContext,
  useEffect,
  useMemo,
  useState,
} from "react";
import { WebsocketContext } from "../../../contexts/websocket-context";
import {
  ChatConflict,
  ChatSessionMessage,
} from "../../../shared/interfaces/chat/chat-history.interface";
import { ChevronDownIcon, ChevronUpIcon } from "@heroicons/react/24/solid";
import { usePostHog } from "posthog-js/react";
import { POSTHOG } from "../../../utils/posthog-constants";
import { useSelector } from "react-redux";
import { selectCurrentProject } from "../../../redux/application-slice";
import clsx from "clsx";
import { QuestionMarkCircleIcon } from "@heroicons/react/24/outline";
import { Tooltip } from "@mantine/core";

const CONFLICT_TOOLTIP_MESSAGE =
  "For each message, Provision checks for any information across the project's documents that may contain conflicting terms or requirements, making it much easier to identify and resolve hard-to-find contradictions.";
const NO_CONFLICTS_MESSAGE = "No conflicting information detected";

interface AIChatConflictProps {
  chatSessionMessage: ChatSessionMessage;
  query: string;
}

const AIChatConflict: React.FC<AIChatConflictProps> = ({
  chatSessionMessage,
  query,
}) => {
  const websocketContext = useContext(WebsocketContext);
  const { conflicts, onCheckConflicts, conflictsThinking, conflictsWriting } =
    websocketContext;
  const [chatSessionMessageID, setChatSessionMessageID] = useState<
    string | null
  >(null);
  const [conflictExpanded, setConflictExpanded] = useState(false);
  const posthog = usePostHog();
  const currentProject = useSelector(selectCurrentProject);

  useEffect(() => {
    if (!chatSessionMessage?.id) {
      return;
    }
    setChatSessionMessageID(chatSessionMessage?.id);
  }, [chatSessionMessage?.id]);

  useEffect(() => {
    if (chatSessionMessage?.conflict !== undefined) {
      return;
    }
    if (!chatSessionMessageID) {
      return;
    }
    if (conflicts[chatSessionMessageID]) {
      return;
    }
    onCheckConflicts(query, chatSessionMessageID);
  }, [chatSessionMessageID]);

  const conflictMessage = useMemo(() => {
    if (chatSessionMessage?.conflict) {
      return chatSessionMessage?.conflict.message;
    }
    if (!chatSessionMessageID) {
      return null;
    }
    const conflictMessages = conflicts[chatSessionMessageID];
    if (conflictMessages) {
      return conflictMessages.content;
    }
    return NO_CONFLICTS_MESSAGE;
  }, [conflicts, chatSessionMessage?.conflict, chatSessionMessageID]);

  const conflictAnswer = useMemo(() => {
    if (!conflictMessage) {
      return NO_CONFLICTS_MESSAGE;
    }
    const paragraphs = conflictMessage.split("\n");
    if (paragraphs.length === 0) {
      return NO_CONFLICTS_MESSAGE;
    }
    const firstParagraph = paragraphs[0];
    if (firstParagraph.toLocaleLowerCase().includes("yes")) {
      return "There may be conflicts present";
    } else if (firstParagraph.toLocaleLowerCase().includes("no")) {
      return NO_CONFLICTS_MESSAGE;
    }
    return firstParagraph;
  }, [conflictMessage]);

  const onClickExpandConflictToggle = useCallback(() => {
    setConflictExpanded((s) => !s);
    posthog?.capture(POSTHOG.document_chat_conflicts_opened, {
      project_uuid: currentProject?.uuid,
      chat_session_message_id: chatSessionMessageID,
    });
  }, [posthog, currentProject?.uuid, chatSessionMessageID]);

  const isConflictInProgress =
    conflictsThinking[chatSessionMessageID] ||
    conflictsWriting[chatSessionMessageID];

  const noConflictFound = conflictAnswer === NO_CONFLICTS_MESSAGE;
  const conflictFound = conflictAnswer !== NO_CONFLICTS_MESSAGE;

  return chatSessionMessageID ? (
    <div className="flex flex-grow-0 !bg-gray-100 rounded-b-lg group flex-col gap-1 border-t p-1.5 pt-1 text-xs">
      <button
        onClick={conflictFound ? onClickExpandConflictToggle : undefined}
        className={clsx("flex bg-gray-100 border-none", {
          "cursor-default text-gray-500": noConflictFound,
          "cursor-pointer": conflictFound,
          "text-gray-500 animate-pulse": isConflictInProgress,
          "group-hover:text-blue-500": !isConflictInProgress && conflictFound,
        })}
        disabled={isConflictInProgress || noConflictFound}
      >
        {isConflictInProgress
          ? "Checking for conflicting information..."
          : noConflictFound
          ? NO_CONFLICTS_MESSAGE
          : `Click to ${conflictExpanded ? "Hide" : "View"} Conflicts`}
        {!isConflictInProgress && !conflictFound ? (
          <Tooltip w="340px" multiline label={CONFLICT_TOOLTIP_MESSAGE}>
            <QuestionMarkCircleIcon className="ml-1 h-4 w-4" />
          </Tooltip>
        ) : null}
        {!isConflictInProgress && conflictFound ? (
          !conflictExpanded ? (
            <ChevronDownIcon className={"ml-1 h-4 w-4"} />
          ) : (
            <ChevronUpIcon className={"ml-1 h-4 w-4"} />
          )
        ) : null}
      </button>
      {conflictExpanded && conflictMessage && (
        <div className="whitespace-pre-line">{conflictMessage}</div>
      )}
    </div>
  ) : null;
};

interface AIChatConflictPreprocessedProps {
  conflict: ChatConflict | undefined;
}

const AIChatConflictPreprocessed: React.FC<AIChatConflictPreprocessedProps> = ({
  conflict,
}) => {
  const [conflictExpanded, setConflictExpanded] = useState(false);
  const posthog = usePostHog();
  const currentProject = useSelector(selectCurrentProject);

  const onClickExpandConflictToggle = useCallback(() => {
    setConflictExpanded((s) => !s);
    posthog?.capture(POSTHOG.document_chat_conflicts_opened, {
      project_uuid: currentProject?.uuid,
      chat_session_message_id: conflict?.chat_session_message,
    });
  }, [posthog, currentProject?.uuid, conflict?.chat_session_message]);

  const conflictMessage = useMemo(() => {
    if (conflict?.message) {
      return conflict.message;
    }
    return NO_CONFLICTS_MESSAGE;
  }, [conflict?.message]);

  const conflictAnswer = useMemo(() => {
    if (!conflict || !conflict.message) {
      return NO_CONFLICTS_MESSAGE;
    }
    const paragraphs = conflict.message.split("\n");
    if (paragraphs.length === 0) {
      return NO_CONFLICTS_MESSAGE;
    }
    const firstParagraph = paragraphs[0];
    if (firstParagraph.toLocaleLowerCase().includes("yes")) {
      return "There may be conflicts present";
    } else if (firstParagraph.toLocaleLowerCase().includes("no")) {
      return NO_CONFLICTS_MESSAGE;
    }
    return firstParagraph;
  }, [conflict?.message]);

  if (!conflict) {
    return (
      <div className="flex flex-grow-0 !bg-gray-100 rounded-b-lg group flex-col gap-1 border-t p-1.5 pt-1 text-xs">
        <button
          className="flex bg-gray-100 border-none cursor-default text-gray-500"
          disabled
        >
          No conflicting information detected
        </button>
      </div>
    );
  }

  const noConflictFound = conflictAnswer === NO_CONFLICTS_MESSAGE;
  const conflictFound = conflictAnswer !== NO_CONFLICTS_MESSAGE;

  return (
    <div className="flex flex-grow-0 !bg-gray-100 rounded-b-lg group flex-col gap-1 border-t p-1.5 pt-1 text-xs">
      <button
        onClick={conflictFound ? onClickExpandConflictToggle : undefined}
        className={clsx("flex bg-gray-100 border-none", {
          "cursor-default text-gray-500": noConflictFound,
          "cursor-pointer group-hover:text-blue-500": conflictFound,
        })}
        disabled={noConflictFound}
      >
        {noConflictFound
          ? NO_CONFLICTS_MESSAGE
          : `Click to ${conflictExpanded ? "Hide" : "View"} Conflicts`}
        {!conflictFound ? (
          <Tooltip w="340px" multiline label={CONFLICT_TOOLTIP_MESSAGE}>
            <QuestionMarkCircleIcon className="ml-1 h-4 w-4" />
          </Tooltip>
        ) : null}
        {conflictFound ? (
          !conflictExpanded ? (
            <ChevronDownIcon className={"ml-1 h-4 w-4"} />
          ) : (
            <ChevronUpIcon className={"ml-1 h-4 w-4"} />
          )
        ) : null}
      </button>
      {conflictExpanded && conflictAnswer && (
        <div className="whitespace-pre-line">{conflictMessage}</div>
      )}
    </div>
  );
};

export { AIChatConflict, AIChatConflictPreprocessed };
