import React from "react";
import { Accordion, Box } from "@mantine/core";
import {
  Square2StackIcon,
  ChatBubbleLeftRightIcon,
  ShieldCheckIcon,
  FunnelIcon,
} from "@heroicons/react/24/solid";

interface FAQItem {
  value: string;
  question: string;
  answer: string;
}

interface FAQSection {
  title: string;
  icon: React.ElementType;
  color: string;
  items: FAQItem[];
}

const faqSections: FAQSection[] = [
  {
    title: "Chat",
    icon: ChatBubbleLeftRightIcon,
    color: "green",
    items: [
      {
        value: "demo-help",
        question: "Why was I sent this demo?",
        answer:
          "We at Provision want to give users a risk-free experience using Provision without having to upload a document or without booking an initial call. With this document, you can ask questions and find conflicts quickly! Like what you see? Book a call with us to see more of Provision.",
      },
      {
        value: "prompt-help",
        question: "I don't use ChatGPT or LLMs. How can I use Chat properly?",
        answer:
          "When using Provision's chat feature, treat it like a conversation with a knowledgeable construction document expert rather than a keyword search tool. Instead of typing single words like 'indemnification', ask complete, specific questions such as 'What is the maintenance term for this project?' or 'Does this document require dewatering permits?' For exploratory inquiries, you can ask broader questions like 'What does the document say about dewatering?'' but remember that these will yield longer, more comprehensive responses. The key is to be clear about what information you're seeking and phrase your questions naturally, as if speaking to a person.",
      },
      {
        value: "what-else-can-do",
        question: "What else can Provision do?",
        answer:
          "We can find conflicting requirements between documents. We can find issues that cost money quickly. And we can customize your list of questions to find things your team cares about, instantly. This enables you to toss out bad projects much more quickly compared to not using Provision.",
      },
      {
        value: "data-backend",
        question: "How is Provision being trained on the back end?",
        answer:
          "Provision was built by people from the construction industry using proprietary data. This is not just ChatGPT, this is construction professionals with over $2B dollars in project experience crafting an experience to help people find and mitigate risks, and ultimately keep more profit.",
      },
      {
        value: "chat-features",
        question: "What can Chat do?",
        answer:
          "Provision's chat feature can identify conflicts, answer specific questions, give context about large questions, supply specific document sources, and more! It's designed to help you understand your documents and answer questions about them.",
      },
    ],
  },
  // {
  //   title: "Risk Review",
  //   icon: ShieldCheckIcon,
  //   color: "red",
  //   items: [
  //     {
  //       value: "dwadaw-upload",
  //       question: "Replace this accordion with a Loom",
  //       answer: `Once you're in a project, click on the "Documents" dropdown and select "Upload New Document". You can then choose files from your computer to upload.`,
  //     },
  //   ],
  // },
  // {
  //   title: "Commenting & Collaboration",
  //   icon: ChatBubbleLeftRightIcon,
  //   color: "purple",
  //   items: [
  //     {
  //       value: "comments-loom",
  //       question: "Replace this accordion with a Loom",
  //       answer: `Once you're in a project, click on the "Documents" dropdown and select "Upload New Document". You can then choose files from your computer to upload.`,
  //     },
  //   ],
  // },
  // {
  //   title: "Clause Filtering",
  //   icon: FunnelIcon,
  //   color: "orange",
  //   items: [
  //     {
  //       value: "clause-filtering-loom",
  //       question: "Replace this accordion with a Loom",
  //       answer: `Once you're in a project, click on the "Documents" dropdown and select "Upload New Document". You can then choose files from your computer to upload.`,
  //     },
  //   ],
  // },
  // {
  //   title: "Supplementary Conditions",
  //   icon: Square2StackIcon,
  //   color: "teal",
  //   items: [
  //     {
  //       value: "supplementary-conditions-loom",
  //       question: "Replace this accordion with a Loom",
  //       answer: `Once you're in a project, click on the "Documents" dropdown and select "Upload New Document". You can then choose files from your computer to upload.`,
  //     },
  //   ],
  // },
];

const FAQContent: React.FC = () => {
  return (
    <>
      <Box p="0" pt="0">
        <Accordion variant="separated" radius="xl">
          {faqSections.map((section, index) => (
            <React.Fragment key={section.title}>
              {index > 0 && <Box mt="xl" />}
              <div className="flex items-center mb-4 ml-4 mt-4">
                <section.icon className="w-6 h-6" color={section.color} />
                <h2 className="text-xl font-semibold ml-2">{section.title}</h2>
              </div>
              {section.items.map((item) => (
                <Accordion.Item key={item.value} value={item.value}>
                  <Accordion.Control>{item.question}</Accordion.Control>
                  <Accordion.Panel>{item.answer}</Accordion.Panel>
                </Accordion.Item>
              ))}
            </React.Fragment>
          ))}
        </Accordion>
      </Box>
    </>
  );
};

export default FAQContent;
