import { useEffect } from "react";
import { useNavigate, useParams } from "react-router-dom";
import { useGetProjectByIdQuery } from "../redux/api-slice";
import { useDispatch, useSelector } from "react-redux";
import {
  setCredits,
  setCurrentDocument,
  setCurrentProject,
  setExpiryDate,
  setAgreedToTerms,
  setHasOpened,
  setPastMessages,
  setMissingDemoSessionId,
} from "../redux/application-slice";
import { toast } from "react-toastify";

// In Open Chat, this only validates the project ID, and assumes that the documentID will always be fetched via API.
export default function useValidateURL() {
  const { openChatSessionId, documentId } = useParams();

  const navigate = useNavigate();
  const dispatch = useDispatch();

  // Fetch the project
  const {
    data: openChatProject,
    isLoading: openChatProjectIsLoading,
    error: openChatProjectError,
  } = useGetProjectByIdQuery(openChatSessionId);

  useEffect(() => {
    if (!openChatProjectIsLoading && openChatProjectError) {
      navigate("/");
    }
  }, [
    openChatProject,
    openChatProjectIsLoading,
    openChatProjectError,
    dispatch,
  ]);

  // Add to redux and handle navigation
  useEffect(() => {
    if (!openChatProjectIsLoading && openChatProject) {
      dispatch(setAgreedToTerms(openChatProject.agreed_to_terms));
      dispatch(setCurrentProject(openChatProject));
      dispatch(setCredits(openChatProject.credits));
      dispatch(setPastMessages(openChatProject.messages));
      dispatch(setExpiryDate(openChatProject.expiry_date));
      dispatch(setHasOpened(openChatProject.has_opened));
      // If we have documents and no specific document ID is provided,
      // set the first document and navigate to it
      if (openChatProject?.project?.documents?.length > 0 && !documentId) {
        const firstDocument = openChatProject.project.documents[0];
        dispatch(setCurrentDocument(firstDocument));
        navigate(`/${openChatSessionId}/chat/${firstDocument.uuid}`);
      } else if (documentId) {
        // If we have a specific document ID, set that as current
        const currentDoc = openChatProject.project?.documents?.find(
          (doc) => doc.uuid === documentId
        );
        if (currentDoc) {
          dispatch(setCurrentDocument(currentDoc));
        } else {
          navigate(`/${openChatSessionId}`);
        }
      }
    }
  }, [
    openChatProject,
    openChatProjectIsLoading,
    documentId,
    navigate,
    dispatch,
    openChatSessionId,
  ]);

  // Throw when openChatSessionID isn't provided, or if it didn't return a project object
  useEffect(() => {
    if (
      !openChatSessionId ||
      (openChatSessionId &&
        openChatProject === null &&
        !openChatProjectIsLoading)
    ) {
      dispatch(setMissingDemoSessionId(true));
      toast.warning(
        "No Demo Session Found. Please check your link was copied correctly, and if this persists, please reach out to support@useprovision.com",
        {
          autoClose: false,
          toastId: "validate-url-warning",
        }
      );
      return;
    }
    dispatch(setMissingDemoSessionId(false));
  }, [openChatSessionId, openChatProject, openChatProjectIsLoading, dispatch]);
}
