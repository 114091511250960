import { RiskSort } from "../../../../components/workflows/risk-review/risk-review-sort-listbox";
import { ProvisionMention } from "../../mention/mention.interface";
import { Quads } from "../../quads.interface";
import { ProvisionUser } from "../../user/user.inteface";
import { InTextCitation } from "../document/in-text-citation/in-text-citation.interface";
import { DocumentSegment } from "../document/segments/document-segment.interface";

export interface Risk {
  id: number;
  questions: RiskQuestion[];
  labels: RiskLabel[];
  risk: string;
  project: number;
}

export interface RiskCategory {
  id?: string;
  name?: string;
  filter_category?: string;
  description?: string;
  created_at?: string;
  updated_at?: string;
  // sources?: Source[]
}

export enum RiskRanking {
  High = 3,
  Medium = 2,
  Low = 1,
}

export enum RiskStatus {
  NEEDS_REVIEW = 3,
  NOT_APPROVED = 2,
  APPROVED = 1,
  NO_STATUS = 0,
}

const RiskStatusIntToStringMap = {
  0: "No Status",
  1: "Approved",
  2: "Not Approved",
  3: "Needs Review",
};

export function RiskStatusIntToString(status: number): string {
  return RiskStatusIntToStringMap[status] || "Unknown";
}

export interface ProjectRisk {
  id?: string;
  project?: number;
  answer_text?: string | null;
  risk_name?: string;
  risk_description?: string;
  risk_lists?: ProjectRiskList[];
  document_segments?: DocumentSegment[];
  sources?: InTextCitation[];
  risk_ranking?: RiskRanking;
  created_at?: string;
  updated_at?: string;
  comments?: ProjectRiskComment[];
  status?: RiskStatus;
  project_uuid?: string;
  processing_status?: number;
  previous_status?: RiskStatus;
  risk_category?: RiskCategory;
  risk_status_filter?: RiskStatus | null;
}

export interface ProjectRiskComment {
  id?: string;
  comment?: string;
  mentions?: ProvisionMention[];
  date_created?: string;
  user?: ProvisionUser;
  user_created?: number;
  document?: number | string;
  project_risk_id?: string;
  parent?: ProjectRisk;
  project?: number;
  project_uuid?: string;
  status?: RiskStatus;
  sort?: RiskSort;
}

export interface ProjectRiskList {
  id?: string;
  name?: string;
}

export interface ProjectRiskFeedback {
  id?: number;
  is_positive: boolean;
  comment?: string;
  project_risk: string;
}

export interface RiskQuestion {
  id: number;
  question?: string;
  answer?: string;
  sources?: RiskQuestionSource[];
  description: string | null;
  risk: number;
}

export interface RiskQuestionSource {
  source: number;
  source_document_uuid: string;
  quads?: [
    {
      x1: number;
      y1: number;
      x2: number;
      y2: number;
      x3: number;
      y3: number;
      x4: number;
      y4: number;
    }
  ];
  answer_index?: number;
}

export interface RiskLabel {
  id: number;
  items?: RiskLabelItem[];
  label: string;
  description: string | null;
  risk: number;
}

export interface RiskLabelItem {
  page: number;
  quads: Quads[];
  document: number;
  content: string;
  id: number;
  risk?: Risk;
  document_uuid?: string;
}

export interface CreateRiskListFormProps {
  onClose: () => void;
}

export type userPermissionsOptions = {
  can_view: boolean;
  can_change: boolean;
  can_run: boolean;
};
export interface RiskListItem {
  id: number;
  name: string;
  user_permissions: userPermissionsOptions;
  created_at: string;
  updated_at: string;
}

export interface RiskListProps {
  id: number;
  name: string;
  userPermissions: userPermissionsOptions;
  projectUuid?: string;
  isLoadingRiskLists?: boolean;
}

export interface CreateRiskFormProps {
  riskListName: string;
}
