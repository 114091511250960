import React, { useCallback } from "react";
import { setModal } from "../../../redux/application-slice";
import { useDispatch } from "react-redux";
import { usePostHog } from "posthog-js/react";
import { POSTHOG } from "../../../utils/posthog-constants";
import { useParams } from "react-router-dom";
import { Tooltip } from "@mantine/core";

interface AIChatFeedbackButtonProps {
  message: string;
  type: "Good" | "Bad";
  sessionUuid: string | null | undefined;
  query: string;
  className?: string;
  icon?: React.ReactNode;
  messageId: string | null;
}

const AIChatFeedbackButton: React.FC<AIChatFeedbackButtonProps> = ({
  message,
  type,
  sessionUuid,
  query,
  className,
  icon,
  messageId,
}) => {
  const posthog = usePostHog();
  const dispatch = useDispatch();
  const { openChatSessionId } = useParams();

  const onPositiveFeedback = useCallback(() => {
    dispatch(
      setModal({
        modal: "AI_CHAT_FEEDBACK",
        data: { positive: true, query, answer: message, messageId },
      })
    );
  }, [dispatch, query, message]);

  const onNegativeFeedback = useCallback(() => {
    dispatch(
      setModal({
        modal: "AI_CHAT_FEEDBACK",
        data: { positive: false, query, answer: message, messageId },
      })
    );
  }, [dispatch, query, message]);

  const onClickFeedback = useCallback(() => {
    posthog?.capture(POSTHOG.chat_feedback_thumbs_up, {
      project_uuid: openChatSessionId,
      chat_session_uuid: sessionUuid,
    });
    if (type === "Good") {
      onPositiveFeedback();
    } else {
      onNegativeFeedback();
    }
  }, [
    onPositiveFeedback,
    onNegativeFeedback,
    posthog,
    openChatSessionId,
    sessionUuid,
    type,
  ]);

  return (
    <Tooltip position="left" label={"Share Feedback on this Response"}>
      <button
        onClick={onClickFeedback}
        className={
          className ||
          `flex-1 rounded bg-gray-200 px-2 z-40 py-0.5 opacity-50 hover:opacity-100 ${
            type === "Good"
              ? "hover:text-green-500 hover:border-green-500"
              : "hover:text-red-500 hover:border-red-500"
          }`
        }
      >
        {icon || type}
      </button>
    </Tooltip>
  );
};

export default AIChatFeedbackButton;
