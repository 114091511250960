import React, { useCallback } from "react";
import { useNavigate, useParams, useSearchParams } from "react-router-dom";
import { setCurrentHighlight } from "../../../redux/application-slice";
import { useDispatch } from "react-redux";
import {
  SelectedSource,
  setSelectedSources,
} from "../../../redux/viewer-slice";
import { v4 as uuidv4 } from "uuid";
import { ProjectDocumentMetadata } from "../../../shared/interfaces/project/document/document.interface";
import { ChatSessionMessageSource } from "../../../shared/interfaces/chat/chat-history.interface";

interface AIChatSourceButtonProps {
  sourceDocument?: ProjectDocumentMetadata;
  source: ChatSessionMessageSource;
  sources: ChatSessionMessageSource[];
  sourceIndex: number;
  displayText: string;
}

const AIChatSourceButton: React.FC<AIChatSourceButtonProps> = ({
  sourceDocument,
  source,
  sourceIndex,
  sources,
  displayText,
}) => {
  const { openChatSessionId } = useParams();

  const navigate = useNavigate();
  const [searchParams, setSearchParams] = useSearchParams();
  const dispatch = useDispatch();

  const onClickSource = useCallback(() => {
    if (!sourceDocument?.uuid) {
      return;
    }

    // Only redirect if the project or document has changed
    const documentId = searchParams.get("documentId");

    if (documentId !== sourceDocument.uuid) {
      navigate(`/${openChatSessionId}/chat/${sourceDocument?.uuid}`);
    }

    const page = source.source.toString();
    searchParams.set("page", page);
    setSearchParams(searchParams);

    dispatch(setCurrentHighlight(source));

    const distinctSources = sources.reduce<ChatSessionMessageSource[]>(
      (acc, citation) => {
        const document_segment_id = citation.document_segment;
        if (!acc.find((c) => c.document_segment === document_segment_id)) {
          acc.push(citation);
        }
        return acc;
      },
      []
    );
    const selectedSources: SelectedSource[] = [];
    for (const chatSource of distinctSources) {
      const highlightID = uuidv4();

      selectedSources.push({
        id: highlightID,
        page: parseInt(chatSource.source.toString()),
        quads: chatSource.source_quads,
        isPrimary: chatSource.id === source.id,
        documentUUID: sourceDocument?.uuid,
      });
    }
    dispatch(setSelectedSources(selectedSources));
  }, [
    openChatSessionId,
    dispatch,
    navigate,
    searchParams,
    setSearchParams,
    source,
    sourceDocument,
    sources,
  ]);

  return (
    <div
      onClick={onClickSource}
      className="cursor-pointer ml-2 flex justify-start items-center hover:text-blue-500"
    >
      <button className="flex justify-center items-center text-black bg-amber-200 hover:bg-amber-300 mr-0.5 px-1 rounded-full w-5 h-5 text-[0.55rem] text-center cursor-pointer">
        <div>{sourceIndex + 1}</div>
      </button>
      <div className="ml-2">{displayText}</div>
    </div>
  );
};

export default AIChatSourceButton;
