import React, { useCallback, useMemo, useState } from "react";
import { ProjectDocumentMetadata } from "../../shared/interfaces/project/document/document.interface";

import {
  Combobox,
  ComboboxButton,
  ComboboxOptions,
  ComboboxOption,
} from "@headlessui/react";
import { ChevronUpDownIcon, XMarkIcon } from "@heroicons/react/20/solid";
import { Tooltip } from "@mantine/core";

interface DocumentListboxProps {
  documents?: ProjectDocumentMetadata[];
  selectedDocuments: ProjectDocumentMetadata[];
  setSelectedDocuments: (d: ProjectDocumentMetadata[]) => void;
  noDefault?: boolean;
  customClass?: string;
  customWidth?: string;
}

const DocumentListboxMulti: React.FC<DocumentListboxProps> = ({
  documents,
  selectedDocuments,
  setSelectedDocuments,
}) => {
  const [query, setQuery] = useState("");

  const filteredDocuments = useMemo(() => {
    return (
      documents?.filter((d) =>
        d.title.toLowerCase().includes(query.toLowerCase())
      ) ?? []
    );
  }, [documents, query]);

  const onClearDocumentsFilter = useCallback(() => {
    setSelectedDocuments([]);
  }, [setSelectedDocuments]);

  return (
    <Tooltip
      label={
        <>
          <span className="font-semibold">
            Ask Questions about specific sets of Documents.
          </span>{" "}
          <br />
          Only available in paid Provision accounts. <br /> Please reach out to
          learn more!
        </>
      }
      position="bottom"
      w="355px"
      multiline
    >
      <Combobox
        as="div"
        value={selectedDocuments.map((d) => d.id)}
        multiple={true}
        style={{
          backgroundColor: "white !important",
        }}
        disabled={true}
      >
        <div className="relative">
          <Combobox.Input
            className="w-full rounded-md opacity-50 cursor-not-allowed border-0 bg-white py-0.5 pl-3 text-gray-900 ring-1 ring-inset ring-gray-300 focus:ring-2 focus:ring-inset focus:ring-blue-600 sm:text-sm sm:leading-6"
            onChange={(event) => setQuery(event.target.value)}
            placeholder={
              selectedDocuments.length > 0
                ? `${selectedDocuments.length} documents`
                : "Filter conversation to specific documents"
            }
          />
          <ComboboxButton className="absolute opacity-50 cursor-not-allowed hover:border-gray-300 bg-white border-top border-left-none border-right border-bottom border-1 border-gray-300 inset-y-0 right-0 flex items-center rounded-r-md px-2 pl-8 focus:outline-none">
            <ChevronUpDownIcon
              className="h-4 w-4 text-gray-400"
              aria-hidden="true"
            />
          </ComboboxButton>

          <ComboboxOptions className="absolute z-10 mt-1 max-h-96 w-full divide-y overflow-hidden overflow-y-scroll rounded-md bg-white py-1 text-base shadow-lg ring-1 ring-black ring-opacity-5 focus:outline-none sm:text-sm">
            {filteredDocuments.map((document) => (
              <ComboboxOption
                key={document.id}
                value={document.id}
                className={`relative cursor-pointer select-none py-2 pl-3 pr-9 text-gray-900 hover:bg-blue-600 hover:text-white ${
                  selectedDocuments.find((d) => d.id === document.id)
                    ? "bg-blue-600 text-white"
                    : ""
                }`}
              >
                {document.title}
              </ComboboxOption>
            ))}
          </ComboboxOptions>
        </div>
      </Combobox>
    </Tooltip>
  );
};

export default DocumentListboxMulti;
