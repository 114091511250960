import React from 'react'
import { isRejectedWithValue, MiddlewareAPI } from '@reduxjs/toolkit'
import { toast } from 'react-toastify'
import { Middleware } from 'react-tooltip'
import * as Sentry from '@sentry/browser'
import { setModal } from './application-slice'
import { MODAL_TYPES } from '../components/modals/modal-controller'

export const rtkQueryErrorLogger: Middleware =
  (api: MiddlewareAPI) => (next) => (action) => {
    if (isRejectedWithValue(action)) {
      Sentry.captureMessage(
        action?.payload?.data?.detail
          ? action?.payload?.data?.detail
          : action?.payload?.error
      )
      if (action?.payload?.status === 'FETCH_ERROR') {
        return
      }
      if (action?.payload?.data?.detail === 'User account is disabled.') {
        api.dispatch(setModal({ modal: MODAL_TYPES.ACCOUNT_LOCKED }))
      } else if (action?.payload?.status === 403) {
        toast.warning(
          <div>
            <div>
              <div className="font-bold">Permission denied</div>
              <div>You do not have the permissions to perform that action</div>
            </div>
          </div>,
          { toastId: 'error', autoClose: 10000 }
        )
      } else if (
        action?.payload?.status === 500 ||
        action?.payload?.status === 400
      ) {
        toast.error(
          <div>
            <div>
              <div className="font-bold">There was an error</div>
              <div>Our support team has been notified</div>
            </div>
          </div>,
          { toastId: 'error', autoClose: 10000 }
        )
      }
    }
    return next(action)
  }
