import React, { useCallback, useContext, useMemo, useState } from "react";
import { Menu, Text, TextInput } from "@mantine/core";
import { ReactElement } from "react";
import { Link, useNavigate } from "react-router-dom";
import {
  ExclamationCircleIcon,
  FolderPlusIcon,
  InformationCircleIcon,
  MagnifyingGlassIcon,
} from "@heroicons/react/24/outline";
import { useForm } from "react-hook-form";
import { useDispatch } from "react-redux";
import { useCreateProjectMutation } from "../../redux/api-slice";
import { ProjectRegion } from "../../shared/interfaces/project/project.interface";
import { DocumentViewerContext } from "../../contexts/document-viewer-instance-context";
import { usePostHog } from "posthog-js/react";
import {
  setCurrentDocument,
  setProjectSidebarOpen,
} from "../../redux/application-slice";
import { POSTHOG } from "../../utils/posthog-constants";
import { toast } from "react-toastify";
import clsx from "clsx";

type MenuPopoverProps = {
  width: number;
  disabled?: boolean;
  topLabel?: string;
  children: React.ReactNode;
  options: {
    label?: string;
    leftSectionIcon?: ReactElement;
    rightSectionText?: string | React.ReactElement;
    onClickMethod?: () => void;
    linkLocation?: string;
    color?: string;
  }[];
  opened?: boolean;
  onChange?: any;
  isSearchable?: boolean;
  type: "Project" | "Document" | "Notifications" | "Settings" | "Sections";
};

type SingleMenuItemProps = {
  uuid?: string;
  leftSectionIcon?: ReactElement;
  rightSectionText?: string | React.ReactElement;
  label?: string;
  linkLocation?: string;
  onClickMethod?: () => void;
  color?: string;
  disabled?: boolean;
};

const SingleMenuItem = React.memo(
  ({
    onClickMethod,
    leftSectionIcon,
    rightSectionText,
    label,
    linkLocation,
    color,
    disabled,
  }: SingleMenuItemProps) => {
    return linkLocation !== undefined ? (
      <Link to={linkLocation}>
        <Menu.Item
          disabled={disabled}
          aria-label={`Navigate to ${label}`}
          data-cy={`project-open-button-${label}`}
          color={color}
          leftSection={leftSectionIcon}
          rightSection={
            <Text size="xs" c="dimmed">
              {rightSectionText}
            </Text>
          }
        >
          {label}
        </Menu.Item>
      </Link>
    ) : (
      <Menu.Item
        disabled={disabled}
        aria-label={`Navigate to ${label}`}
        data-cy={`project-open-button-${label}`}
        onClick={onClickMethod}
        color={color}
        leftSection={leftSectionIcon}
        rightSection={
          <Text size="xs" c="dimmed">
            {rightSectionText}
          </Text>
        }
      >
        {label}
      </Menu.Item>
    );
  }
);
SingleMenuItem.displayName = "SingleMenuItem";

const SearchAndNewHeader = ({
  searchQuery,
  handleSearchChange,
  listSize,
  type,
  lastItem,
}: {
  searchQuery: string;
  handleSearchChange: (event: React.ChangeEvent<HTMLInputElement>) => void;
  listSize: number;
  type: "Project" | "Document" | "Notifications" | "Settings" | "Sections";
  lastItem: SingleMenuItemProps | undefined;
}) => {
  return (
    <>
      <div className="grid grid-cols-[repeat(2,_1fr)] grid-rows-[1fr] gap-x-[8px] gap-y-[0px] p-1.5">
        <div className="[grid-area:1_/_1_/_2_/_2]">
          <TextInput
            leftSection={<MagnifyingGlassIcon className="h-5 w-5 text-black" />}
            disabled={listSize === 0 && searchQuery === ""}
            value={searchQuery}
            onChange={handleSearchChange}
            placeholder={`Search for ${type}`}
            classNames={{
              input:
                "!rounded-md text-sm !text-black !border-gray-200 active:border-gray-400",
            }}
          />
        </div>

        {/* The last item is always an action with an onclick, usually the primary action of the menu */}
        {/* Because it is searchable, we move that action to the top of the list */}
        <div className="rounded-lg border border-gray-200 [grid-area:1_/_2_/_2_/_3]">
          {lastItem ? <SingleMenuItem {...lastItem} /> : null}
        </div>
      </div>
      <Menu.Divider />
    </>
  );
};

const MenuContent = ({
  searchQuery,
  handleSearchChange,
  filteredOptions,
  type,
  setSearchQuery,
  children,
  width,
  isSearchable,
  topLabel,
}: {
  searchQuery: string;
  handleSearchChange: (event: React.ChangeEvent<HTMLInputElement>) => void;
  filteredOptions: SingleMenuItemProps[];
  type: "Project" | "Document" | "Notifications" | "Settings" | "Sections";
  setSearchQuery: React.Dispatch<React.SetStateAction<string>>;
  children: React.ReactNode;
  width: number;
  isSearchable: boolean;
  topLabel: string | undefined;
}) => {
  const otherItems = useMemo(
    () => filteredOptions.slice(0, -1),
    [filteredOptions]
  );
  const AllItemsExceptLastItem = useMemo(() => {
    return otherItems.map(
      ({
        label,
        leftSectionIcon,
        rightSectionText,
        onClickMethod,
        linkLocation,
        color,
        uuid,
        disabled,
      }) => (
        <SingleMenuItem
          key={uuid ? uuid : label}
          label={label}
          leftSectionIcon={leftSectionIcon}
          rightSectionText={rightSectionText}
          onClickMethod={!disabled ? onClickMethod : undefined}
          linkLocation={!disabled ? linkLocation : undefined}
          color={color}
          disabled={disabled}
        />
      )
    );
  }, [otherItems]);

  const lastItem = filteredOptions[filteredOptions.length - 1];
  const hasNoElements = filteredOptions.length === 1;

  const NoResults = () => {
    return (
      <div
        className={clsx(
          "m-1.5 flex h-full !cursor-pointer items-center justify-center rounded-md border border-[#ffffff00] py-4 text-gray-700 transition-colors hover:border-red-300 hover:bg-rose-200 hover:text-red-800",
          {
            "min-h-[calc(230px-14px)]": type === "Project",
          }
        )}
        onClick={() => setSearchQuery("")}
      >
        <div className="flex !cursor-pointer flex-col items-center justify-center">
          <ExclamationCircleIcon className="mb-2 h-10 w-10" />
          <Text>No results. Click to clear search.</Text>
        </div>
      </div>
    );
  };

  const CreateNewCTAPanel = () => {
    return (
      <div
        className={clsx(
          "m-1.5 flex h-full items-center justify-center rounded-md border border-[#ffffff00] py-4 text-gray-400 transition-colors",
          {
            "min-h-[calc(230px-14px)]": type === "Project",
          }
        )}
        onClick={() => setSearchQuery("")}
      >
        <div className="flex flex-row items-center justify-center">
          <Text>Documents Loading...</Text>
        </div>
      </div>
    );
  };

  const hasNoMenuItemsAvailable =
    searchQuery === "" && hasNoElements && type !== "Notifications";
  const hasNoMenuItemsWithinSearchCriteria =
    searchQuery !== "" && hasNoElements && type !== "Notifications";

  return (
    <>
      <Menu.Target>
        <div>{children}</div>
      </Menu.Target>
      <Menu.Dropdown maw={width} w={width}>
        {isSearchable ? (
          <SearchAndNewHeader
            searchQuery={searchQuery}
            handleSearchChange={handleSearchChange}
            listSize={filteredOptions.slice(0, -1).length}
            type={type}
            lastItem={lastItem}
          />
        ) : null}
        <div
          className={clsx("max-h-[60vh] overflow-y-auto scroll-smooth", {
            "min-h-[230px]": type === "Project",
          })}
        >
          {hasNoMenuItemsAvailable && type !== "Sections" ? (
            <CreateNewCTAPanel />
          ) : hasNoMenuItemsWithinSearchCriteria ? (
            <NoResults />
          ) : (
            <>
              {topLabel && filteredOptions.slice(0, -1).length > 0 ? (
                <Menu.Label pt="8px">
                  {searchQuery ? "Search Results from List " : topLabel}
                </Menu.Label>
              ) : null}

              {/* MENU ITEMS */}
              {AllItemsExceptLastItem}

              {/* FINAL MENU ITEM */}
              {/* When the menu is not searchable, the last item is always an action with an onclick, usually the primary action of the menu */}
              {!isSearchable && lastItem ? (
                <SingleMenuItem {...lastItem} />
              ) : null}
            </>
          )}
        </div>
      </Menu.Dropdown>
    </>
  );
};

const NewProjectMenuPanel = () => {
  const documentViewerContext = useContext(DocumentViewerContext);
  const { documentViewer } = documentViewerContext;
  const [submitting, setSubmitting] = useState(false);
  const { register, handleSubmit, reset } = useForm();
  const [createProject] = useCreateProjectMutation();
  const [region, setRegion] = useState<ProjectRegion>("GLOBAL");
  // const { data: user } = useGetUserProfileQuery(undefined);
  const dispatch = useDispatch();
  const posthog = usePostHog();
  const navigate = useNavigate();

  const [isDefinitionsChecked, setIsDefinitionsChecked] = useState(false);
  const [isShareProjectChecked, setIsSharedProjectChecked] = useState(false);

  const onChangeDefinitionChecked = useCallback(() => {
    setIsDefinitionsChecked((d) => !d);
  }, []);

  const onChangeProjectSharedChecked = useCallback(() => {
    setIsSharedProjectChecked((d) => !d);
  }, []);

  const onSelectRegion = useCallback(
    (e: React.ChangeEvent<HTMLSelectElement>) => {
      setRegion(e.target.value as ProjectRegion);
    },
    []
  );

  const onClose = useCallback(() => {
    setIsDefinitionsChecked(false);
    setIsSharedProjectChecked(false);
    setSubmitting(false);
    setRegion("GLOBAL");
    reset();
  }, [reset]);

  const onSubmit = useCallback(
    async (data) => {
      if (submitting) return;

      setSubmitting(true);
      try {
        const project = await createProject({
          title: data.project_name,
          share_definitions: isDefinitionsChecked,
          is_shared: isShareProjectChecked,
          region,
        }).unwrap();
        setTimeout(() => {
          documentViewer?.closeDocument();
        }, 500);
        dispatch(setCurrentDocument(null));
        posthog?.capture(POSTHOG.project_created, {
          project_uuid: project.uuid,
        });
        dispatch(setProjectSidebarOpen(false));
        navigate(`${project?.uuid}/documents`);
      } catch (error) {
        toast.error("Something went wrong. Please try again.");
        setSubmitting(false);
      }
      onClose();
    },
    [
      createProject,
      dispatch,
      documentViewer,
      isDefinitionsChecked,
      navigate,
      posthog,
      submitting,
      onClose,
      isShareProjectChecked,
      region,
    ]
  );

  const definitionContent = useMemo(() => {
    return (
      <div className="relative mt-5 flex items-start">
        <div className="flex h-6 items-center">
          <input
            id="not-legal-checkbox"
            aria-describedby="not-legal-description"
            name="not-legal"
            type="checkbox"
            checked={isDefinitionsChecked}
            onChange={onChangeDefinitionChecked}
            className="h-4 w-4 cursor-pointer rounded border-gray-300 text-blue-600 focus:ring-blue-600"
          />
        </div>
        <div className="ml-3 text-sm leading-6">
          <label
            htmlFor="not-legal-checkbox"
            className="cursor-pointer font-medium text-gray-900"
          >
            <div className="text-s">Share Definitions between documents</div>
            <div className="text-xs">
              Definitions will be shared between all documents in this project.
              Only enable this if you are reviewing a multi-schedule contract.
            </div>
          </label>
        </div>
      </div>
    );
  }, [isDefinitionsChecked, onChangeDefinitionChecked]);

  const sharedProjectContent = useMemo(() => {
    return (
      <div className="relative mt-5 flex items-start">
        <div className="flex h-6 items-center">
          <input
            id="shared-project-checkbox"
            aria-describedby="share-project-description"
            name="share-project"
            type="checkbox"
            checked={isShareProjectChecked}
            onChange={onChangeProjectSharedChecked}
            className="h-4 w-4 cursor-pointer rounded border-gray-300 text-blue-600 focus:ring-blue-600"
          />
        </div>
        <div className="ml-3 text-sm leading-6">
          <label
            htmlFor="shared-project-checkbox"
            className="cursor-pointer font-medium text-gray-900"
          >
            <div className="text-s">Share Project</div>
            <div className="text-xs">
              Project will be shared with all users in your organization.
            </div>
          </label>
        </div>
      </div>
    );
  }, [isShareProjectChecked, onChangeProjectSharedChecked]);

  const projectRegionContent = useMemo(() => {
    return (
      <div
        data-cy={`project-region-content`}
        className="relative mt-5 flex flex-col items-start"
      >
        <label
          htmlFor="project-region"
          className="flex items-center space-x-1 text-sm font-medium text-gray-700"
        >
          <span>Project Region</span>
          <InformationCircleIcon
            data-tooltip-id="project-region-tooltip"
            data-tooltip-content={
              "Select the region for the project.\nThis value determines where the project data is stored.\nThis value cannot be changed once set. If you are unsure, select North America."
            }
            className="h-5 w-5 text-gray-700"
          />
        </label>
        <select
          name="project-region"
          id="project-region"
          value={region}
          className="mt-2 block w-full rounded-md border-0 py-1.5 pl-3 pr-10 text-gray-900 ring-1 ring-inset ring-gray-300 focus:ring-2 focus:ring-blue-600 sm:text-sm sm:leading-6"
          onChange={onSelectRegion}
        >
          <option value="GLOBAL">North America</option>
          <option value="CANADA">Canada</option>
        </select>
      </div>
    );
  }, [onSelectRegion, region]);

  const dialogContent = useMemo(() => {
    return (
      <form onSubmit={handleSubmit(onSubmit)}>
        <div>
          <div>
            <label
              htmlFor="email"
              className="block text-sm font-medium text-gray-700"
            >
              Project Name
            </label>
            <input
              {...register("project_name")}
              tabIndex={0}
              type="text"
              required={true}
              name="project_name"
              id="project_name"
              className="mt-1 block w-full rounded-md border-gray-300 shadow-sm focus:border-blue-500 focus:ring-blue-500 sm:text-sm"
            />
          </div>
          {/* {user?.feature_flags?.project_regions === true
            ? projectRegionContent
            : null} */}
          {definitionContent}
          {sharedProjectContent}
          <div className="mt-5 sm:mt-6">
            <button
              data-cy="create-project-button"
              type="submit"
              className="inline-flex w-full justify-center rounded-md border border-transparent bg-blue-600 px-4 py-2 text-base font-medium text-white shadow-sm hover:bg-blue-700 focus:outline-none focus:ring-2 focus:ring-blue-500 focus:ring-offset-2 sm:text-sm"
            >
              Create
            </button>
          </div>
        </div>
      </form>
    );
  }, [
    handleSubmit,
    onSubmit,
    register,
    projectRegionContent,
    definitionContent,
    sharedProjectContent,
    // user,
  ]);

  return <div className="p-4">{dialogContent}</div>;
};

const NewProjectMenuContent = ({
  children,
  width,
}: {
  children: React.ReactNode;
  width: number;
}) => {
  return (
    <>
      <Menu.Target>{children}</Menu.Target>
      <Menu.Dropdown maw={width}>
        <div className="my-2 flex items-center justify-center p-1.5 text-gray-500">
          <FolderPlusIcon className="mr-2 h-5 w-5" />
          Create a New Project
        </div>
        <Menu.Divider />
        <div className="max-h-[75vh] min-h-[230px] overflow-y-auto scroll-smooth">
          <NewProjectMenuPanel />
        </div>
        <Menu.Divider />
      </Menu.Dropdown>
    </>
  );
};

export const MenuPopover = ({
  disabled,
  width,
  children,
  topLabel,
  options,
  isSearchable = true,
}: MenuPopoverProps) => {
  const [searchQuery, setSearchQuery] = useState("");

  const handleSearchChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setSearchQuery(event.target.value);
  };

  // Always preserve the last entry; it is the action associated with the menu
  // For example, in the Projects dropdown, the final option is the "New Project" button
  const filteredOptions = useMemo(
    () =>
      options
        .slice(0, -1)
        .filter((option) =>
          option.label?.toLowerCase().includes(searchQuery.toLowerCase())
        )
        .concat(options.slice(-1)),
    [options, searchQuery]
  );

  return (
    <Menu
      disabled={disabled}
      transitionProps={{ transition: "scale", duration: 200 }}
      shadow="md"
      radius={"md"}
      width={width}
      withArrow
      arrowSize={12}
    >
      <MenuContent
        searchQuery={searchQuery}
        handleSearchChange={handleSearchChange}
        filteredOptions={filteredOptions}
        type={"Document"}
        setSearchQuery={setSearchQuery}
        width={width}
        isSearchable={isSearchable}
        topLabel={topLabel}
      >
        {children}
      </MenuContent>
    </Menu>
  );
};
