import React, { useCallback } from "react";
import { ChatSuggestion } from "./ai-chat";
import { ChevronRightIcon } from "@heroicons/react/24/outline";

interface AIChatSuggestionProps {
  suggestion: ChatSuggestion;
  onSendMessage: (message: string) => void;
  setChatInput: React.Dispatch<React.SetStateAction<string>>;
}

const AIChatSuggestion: React.FC<AIChatSuggestionProps> = ({
  suggestion,
  onSendMessage,
  setChatInput,
}) => {
  const onSuggestionClick = useCallback(() => {
    onSendMessage(suggestion.suggestion);
    setChatInput("");
  }, [setChatInput, onSendMessage, suggestion]);
  return (
    <button
      key={suggestion.suggestion}
      onClick={onSuggestionClick}
      className="btn btn-neutral group relative flex w-full items-center justify-between whitespace-nowrap rounded-lg border-2 bg-gray-50 px-3 py-2 text-left text-gray-700 shadow-[0px_1px_6px_0px_rgba(0,0,0,0.02)] transition-colors hover:border-blue-400  hover:bg-gray-100 md:whitespace-normal"
    >
      <div>
        <div className="text-xs font-bold">{suggestion.topic}</div>
        <div className="text-xs">{suggestion.suggestion}</div>
      </div>
      <div>
        <ChevronRightIcon width={16} />
      </div>
    </button>
  );
};

export default AIChatSuggestion;
