import React, {
  ReactElement,
  useCallback,
  useEffect,
  useMemo,
  useState,
} from "react";
import { useDispatch, useSelector } from "react-redux";
import { selectCurrentProject } from "../redux/application-slice";
import { Project } from "../shared/interfaces/project/project.interface";
import { ProjectDocumentMetadata } from "../shared/interfaces/project/document/document.interface";
import { useLocation, useNavigate, useParams } from "react-router-dom";
import { XMarkIcon, DocumentIcon } from "@heroicons/react/24/outline";

// import { POSTHOG } from "../utils/posthog-constants";
// import { usePostHog } from "posthog-js/react";

// Types
type DropdownOption = {
  label: string;
  leftSectionIcon: ReactElement;
  rightSectionText: string;
  onClickMethod?: () => void;
  linkLocation?: string;
  color?: string;
};

interface Document {
  uuid?: string;
  title?: string;
}

// Hooks
export const useMenuOptions = () => {
  const dispatch = useDispatch();
  // const posthog = usePostHog();

  const { pathname } = useLocation();

  const { openChatSessionId, documentId } = useParams<{
    openChatSessionId: string;
    documentId?: string;
  }>();

  const openChatProject = useSelector(selectCurrentProject);

  const [selectedDocument, setSelectedDocument] = useState<
    ProjectDocumentMetadata | { uuid?: string; title: string }
  >(
    documentId !== ""
      ? { uuid: documentId, title: "" }
      : { uuid: "", title: "" }
  );

  // Callbacks
  const navigate = useNavigate();

  const onDocumentClick = useCallback(
    (uuid: string) => {
      navigate(`/${openChatSessionId ?? openChatProject?.uuid}/chat/${uuid}`);
      dispatch({ type: "SET_CURRENT_PAGE", payload: 1 });
      setSelectedDocument({ uuid, title: "" });
    },
    [openChatProject, dispatch, navigate]
  );

  // Effects
  useEffect(() => {
    const newDocumentObject = selectedDocument;
    newDocumentObject.title =
      getLabelForCurrentCollectionFromUrl(
        openChatProject?.documents ?? [],
        documentId ?? "",
        "Document"
      ) ?? "";
    setSelectedDocument(newDocumentObject);
  }, [documentId, openChatProject, pathname, selectedDocument]);

  // Helper Functions
  const getLabelForCurrentCollectionFromUrl = (
    collection: { uuid: string; title: string }[],
    uuid: string,
    defaultObjectForLabel: string
  ): string | undefined =>
    collection.find((item) => item.uuid === uuid)?.title ??
    `All ${defaultObjectForLabel}s`;

  const getDocumentStatus = useCallback(
    (document: Document, selectedDocument: Document): string => {
      // If either document is null or undefined, return an empty string
      if (!document || !selectedDocument) return "";

      // Check if both documents have UUIDs and they match
      if (
        document.uuid &&
        selectedDocument.uuid &&
        document.uuid === selectedDocument.uuid
      ) {
        return "Selected";
      }

      // If UUIDs don't match or aren't available, check if titles match
      if (
        document.title &&
        selectedDocument.title &&
        document.title === selectedDocument.title
      ) {
        return "Selected";
      }

      // If neither UUID nor title matches, return an empty string
      return "";
    },
    [document, selectedDocument.uuid]
  );

  const DOCUMENT_DROPDOWN_OPTIONS = useMemo(() => {
    const buildDocumentDropdownOptions = (
      project: Project | null | undefined,
      onDocumentClick: (uuid: string) => void
    ): DropdownOption[] => {
      const options: DropdownOption[] = (project?.project?.documents ?? []).map(
        (document) => ({
          label: document.title ?? "Untitled Document",
          leftSectionIcon: (
            <DocumentIcon className="h-6 w-6 text-gray-500 group-hover:text-black" />
          ),
          rightSectionText: getDocumentStatus(document, selectedDocument),
          onClickMethod: () => onDocumentClick(document?.uuid ?? "error"),
        })
      );

      options.push({
        label: "Close Menu",
        leftSectionIcon: (
          <XMarkIcon className="h-5 w-5 text-red-600 group-hover:text-black" />
        ),
        rightSectionText: "",
        color: "red",
      });

      return options;
    };

    return buildDocumentDropdownOptions(openChatProject, onDocumentClick);
  }, [openChatProject, onDocumentClick, selectedDocument, getDocumentStatus]);

  return {
    DOCUMENT_DROPDOWN_OPTIONS,
  };
};
