import { StrictMode } from "react";
import { createRoot } from "react-dom/client";
import App from "./App.tsx";
import "./index.css";
import { PostHogProvider } from "posthog-js/react";
import * as Sentry from "@sentry/react";
import React from "react";

import { Provider } from "react-redux";
import { store } from "./redux/store";

// TODO VITE_SENTRY_DSN and VITE_ENVIRONMENT need to properly configured for prod
Sentry.init({
  dsn: import.meta.env.VITE_SENTRY_DSN,
  integrations: [
    Sentry.reactRouterV6BrowserTracingIntegration({
      useEffect: React.useEffect,
      useLocation: React.useLocation,
      useNavigationType: React.useNavigationType,
      createRoutesFromChildren: React.createRoutesFromChildren,
      matchRoutes: React.matchRoutes,
    }),
    Sentry.replayIntegration(),
  ],
  replaysSessionSampleRate: 0.5,
  replaysOnErrorSampleRate: 1.0,
  environment: import.meta.env.VITE_ENVIRONMENT,
  tracesSampleRate: 0.1,
  tracePropagationTargets: ["localhost", /^https:\/\/useprovision\.com\/api/],
  profilesSampleRate: 0.1,
});

const options = {
  api_host: import.meta.env.VITE_PUBLIC_POSTHOG_HOST,
};

createRoot(document.getElementById("root")).render(
  <StrictMode>
    <PostHogProvider
      apiKey={import.meta.env.VITE_PUBLIC_POSTHOG_KEY}
      options={options}
    >
      <Provider store={store}>
        <App />
      </Provider>
    </PostHogProvider>
  </StrictMode>
);
