import React from "react";
import { selectModal } from "../../redux/application-slice";
import { useSelector } from "react-redux";
import AIChatFeedbackModal from "./ai-chat-feedback-modal";
import BookAMeetingModal from "./book-a-meeting-modal";
import LearnQuestionsModal from "./learn-questions-modal";
import FAQModal from "./faq-modal";

export const MODAL_TYPES = {
  AI_CHAT_FEEDBACK: "AI_CHAT_FEEDBACK",
  BOOK_A_MEETING: "BOOK_A_MEETING",
  LEARN_QUESTIONS: "LEARN_QUESTIONS",
  FAQ: "FAQ",
};

function ModalController() {
  const modalOpen = useSelector(selectModal);

  return (
    <>
      <LearnQuestionsModal
        open={modalOpen?.modal === MODAL_TYPES.LEARN_QUESTIONS}
      />
      <BookAMeetingModal
        open={modalOpen?.modal === MODAL_TYPES.BOOK_A_MEETING}
      />
      <AIChatFeedbackModal
        open={modalOpen?.modal === MODAL_TYPES.AI_CHAT_FEEDBACK}
      />
      <FAQModal open={modalOpen?.modal === MODAL_TYPES.FAQ} />
    </>
  );
}

export default ModalController;
