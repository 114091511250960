import React from "react";
import { useSelector } from "react-redux";
import { selectDocumentViewerSidebarOpen } from "../redux/viewer-slice";

const DocumentViewerSidebar: React.FC = () => {
  const documentViewerSidebarOpen = useSelector(
    selectDocumentViewerSidebarOpen
  );

  return (
    <div
      className={`absolute right-full top-12 z-[39] max-h-[50vh] min-h-32 w-96 rounded-lg border border-[#dbdee3] bg-white pb-1 shadow-lg hover:border-yellow-400 ${
        documentViewerSidebarOpen === "search"
          ? "overflow-visible rounded"
          : "overflow-auto"
      }`}
      style={{
        maxHeight:
          documentViewerSidebarOpen === "search" ? "" : "calc(100vh - 5rem)",
      }}
    ></div>
  );
};

export default DocumentViewerSidebar;
