export const pageUtils = {
  // Convert from PDF viewer's 0-based index to UI's 1-based index
  toDisplayPage: (viewerPage: number): number => viewerPage + 1,

  // Convert from UI's 1-based index to PDF viewer's 0-based index
  toViewerPage: (displayPage: number): number => displayPage - 1,

  // Ensure page number is within valid range
  validatePage: (page: number, totalPages: number): number => {
    return Math.max(1, Math.min(page, totalPages));
  },
};
