import { ChevronDownIcon, ChevronUpIcon } from "@heroicons/react/24/solid";
import React, { useCallback, useState } from "react";
import { ProjectDocumentMetadata } from "../../../shared/interfaces/project/document/document.interface";
import AIChatSourceButton from "./ai-chat-source-button";
import { ChatSessionMessage } from "../../../shared/interfaces/chat/chat-history.interface";

interface AiChatSourceProps {
  chatMessage: ChatSessionMessage;
  documents: ProjectDocumentMetadata[];
}
const AiChatSource: React.FC<AiChatSourceProps> = ({
  chatMessage,
  documents,
}) => {
  const [sourcesExpanded, setSourcesExpanded] = useState(false);

  const onClickSourcesToggle = useCallback(() => {
    setSourcesExpanded((s) => !s);
  }, []);

  return (
    <div className="flex flex-grow-0 !bg-gray-100 group flex-col gap-1 border-t p-1.5 pt-1 text-xs">
      <button
        onClick={onClickSourcesToggle}
        className="flex cursor-pointer bg-gray-100 border-none group-hover:text-blue-500"
      >
        Click to {sourcesExpanded ? "Hide" : "View"} All Sources{" "}
        {!sourcesExpanded ? (
          <ChevronDownIcon className={"ml-1 h-4 w-4"} />
        ) : (
          <ChevronUpIcon className={"ml-1 h-4 w-4"} />
        )}
      </button>
      {sourcesExpanded && (
        <div
          className={"flex flex-col space-y-2 border-t border-gray-200 pt-1.5"}
        >
          <div>
            {chatMessage.message_sources?.map((source, sourceIndex) => {
              const sourceDocument = documents?.find(
                (d) => d.id === source.document
              );
              return (
                <div
                  key={`chat_source_${source.id}`}
                  className="mb-0.5 p-1 flex items-center"
                >
                  <AIChatSourceButton
                    sourceDocument={sourceDocument}
                    source={source}
                    sources={chatMessage.message_sources ?? []}
                    sourceIndex={sourceIndex}
                    displayText={`${sourceDocument?.title} - pg. ${source.source}`}
                  />
                </div>
              );
            })}
          </div>
          {chatMessage?.filtered_documents?.length ? (
            <div>
              <div className="font-bold">Filtered Documents:</div>
              {chatMessage?.filtered_documents?.map((projectDocument) => {
                return (
                  <div key={projectDocument.uuid}>{projectDocument.title}</div>
                );
              })}
            </div>
          ) : null}
        </div>
      )}
    </div>
  );
};

export default AiChatSource;
