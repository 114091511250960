import { Popover, PopoverButton, PopoverPanel } from "@headlessui/react";
import React, { useState } from "react";
import { usePopper } from "react-popper";
import {
  selectShowCustomLabels,
  selectShowDefinitions,
  selectShowRevisionHighlights,
  setShowCustomLabels,
  setShowDefinitions,
  setShowRevisionHighlights,
} from "../../../redux/viewer-slice";
import { useDispatch, useSelector } from "react-redux";
import {
  ChatBubbleLeftRightIcon,
  EyeIcon,
  EyeSlashIcon,
} from "@heroicons/react/24/outline";
import { Tooltip as MantineTooltip } from "@mantine/core";

const DocumentViewerViewOptions: React.FC = () => {
  const [referenceElement, setReferenceElement] = useState<HTMLElement | null>(
    null
  );
  const [popperElement, setPanelRef] = useState<HTMLElement | null>(null);
  const { styles, attributes } = usePopper(referenceElement, popperElement);
  const showRevisionHighlights = useSelector(selectShowRevisionHighlights);
  const showCustomLabels = useSelector(selectShowCustomLabels);
  const showDefinitions = useSelector(selectShowDefinitions);
  const dispatch = useDispatch();

  const onToggleShowRevisionHighlights = () => {
    dispatch(setShowRevisionHighlights(!showRevisionHighlights));
  };

  const onToggleShowCustomLabels = () => {
    dispatch(setShowCustomLabels(!showCustomLabels));
  };

  const onToggleShowDefinitions = () => {
    dispatch(setShowDefinitions(!showDefinitions));
  };

  return (
    <div>
      <Popover>
        <MantineTooltip
          label={
            <>
              <span className="font-semibold">
                Collaborate & Iterate with your team.
              </span>{" "}
              <br />
              Only available in paid Provision accounts. <br /> Please reach out
              to learn more!
            </>
          }
          position="bottom"
          w="320px"
          multiline
        >
          <PopoverButton
            disabled={true}
            className={
              "group rounded-lg bg-[#edf0f5] cursor-not-allowed border-2 border-[#ffffff00] px-2 py-1.5 transition-colors hover:border-2 hover:border-[#e5e7eb] hover:!bg-[#fff]"
            }
            tabIndex={-1}
            ref={setReferenceElement}
          >
            <div className="flex items-center justify-center text-gray-400 group-hover:text-black">
              <ChatBubbleLeftRightIcon className="mr-1 h-4 w-4 text-gray-400 group-hover:text-black" />
              Comments
            </div>
          </PopoverButton>
        </MantineTooltip>

        <PopoverPanel
          ref={setPanelRef}
          style={styles.popper}
          {...attributes.popper}
          className="z-50 flex flex-col rounded border bg-white shadow"
        >
          <button
            className="relative flex items-center gap-2 p-2 text-sm"
            onClick={onToggleShowRevisionHighlights}
          >
            {showRevisionHighlights ? (
              <EyeIcon className="h-4 w-4" />
            ) : (
              <EyeSlashIcon className="h-4 w-4" />
            )}
            {showRevisionHighlights ? "Hide" : "Show"} Comment & Revision
            Highlighting
          </button>

          <button
            className="relative flex items-center gap-2 p-2 text-sm"
            onClick={onToggleShowCustomLabels}
          >
            {showCustomLabels ? (
              <EyeIcon className="h-4 w-4" />
            ) : (
              <EyeSlashIcon className="h-4 w-4" />
            )}
            {showCustomLabels ? "Hide" : "Show"} Labels & Tags Highlighting
          </button>
          <button
            className="relative flex items-center gap-2 p-2 text-sm"
            onClick={onToggleShowDefinitions}
          >
            {showDefinitions ? (
              <EyeIcon className="h-4 w-4" />
            ) : (
              <EyeSlashIcon className="h-4 w-4" />
            )}
            {showDefinitions ? "Hide" : "Show"} Definition Highlighting
          </button>
        </PopoverPanel>
      </Popover>
    </div>
  );
};

export default DocumentViewerViewOptions;
