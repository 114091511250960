import React from "react";
import DocumentViewer from "./document-viewer/document-viewer";
import { ErrorBoundary } from "@sentry/react";
import ErrorMessage from "./workflows/workflow-components/error-message";
import { useSelector } from "react-redux";
import { selectDocumentViewerSidebarOpen } from "../redux/viewer-slice";
import DocumentViewerSidebar from "./document-viewer-sidebar";

interface DocumentSidebarProps {
  width: string | number;
}

const DocumentSidebar: React.FC<DocumentSidebarProps> = ({ width }) => {
  const documentViewerSidebarOpen = useSelector(
    selectDocumentViewerSidebarOpen
  );

  return (
    <div
      className="h-full w-full"
      style={{
        width,
      }}
    >
      <ErrorBoundary fallback={ErrorMessage}>
        {documentViewerSidebarOpen && <DocumentViewerSidebar />}
        <DocumentViewer />
      </ErrorBoundary>
    </div>
  );
};

export default DocumentSidebar;
