import React, {
  Fragment,
  useCallback,
  useEffect,
  useMemo,
  useRef,
  useState,
} from "react";
import { Dialog, DialogPanel, Transition } from "@headlessui/react";
import { ArrowRightIcon } from "@heroicons/react/24/outline";
import { useDispatch, useSelector } from "react-redux";
import {
  selectExpiryDate,
  selectAgreedToTerms,
  setIsBookAMeetingModalUnclosable,
  setModal,
} from "../../redux/application-slice";
import { MODAL_TYPES } from "../modals/modal-controller";
import { toast } from "react-toastify";
import { Divider } from "@mantine/core";
import { useParams } from "react-router-dom";
import { useUpdateOpenChatSessionTermsMutation } from "../../redux/api-slice";

function TermsAndConditions() {
  const [openWelcomeModal, setOpenWelcomeModal] = useState<boolean>(false);
  const cancelButtonRef = useRef<HTMLButtonElement | null>(null);

  const agreedToTerms = useSelector(selectAgreedToTerms);

  useEffect(() => {
    if (agreedToTerms === false) {
      setOpenWelcomeModal(true);
    }
  }, [agreedToTerms]);

  const expiryDate = useSelector(selectExpiryDate);
  const isExpired = () => {
    if (expiryDate === null) {
      return false;
    }
    const today = new Date();
    const expiry = new Date(expiryDate);
    const expired = today > expiry;
    return expired;
  };
  const hasSessionExpired = isExpired();

  useEffect(() => {
    if (hasSessionExpired === true) {
      setOpenWelcomeModal(false);
      dispatch(setIsBookAMeetingModalUnclosable(true)); // Make modal unclosable
      toast.error(
        "Your session has expired. Please connect with us to extend your trial or see a full demo of the Provision platform."
      );
      dispatch(setModal({ modal: MODAL_TYPES.BOOK_A_MEETING }));
    }
  }, [expiryDate]);

  const onCloseOverride = useCallback(() => {
    // Overriding default close
  }, []);

  const dispatch = useDispatch(); // Add this line

  const onSalesClick = useCallback(() => {
    setOpenWelcomeModal(false);
    dispatch(
      setModal({
        modal: MODAL_TYPES.BOOK_A_MEETING,
        props: {
          fromTerms: true,
          onBack: () => setOpenWelcomeModal(true),
        },
      })
    );
  }, [dispatch]);

  const { openChatSessionId } = useParams();
  const [updateTerms] = useUpdateOpenChatSessionTermsMutation();

  const onClose = useCallback(async () => {
    try {
      if (openChatSessionId) {
        await updateTerms({ openChatSessionId }).unwrap();
      }
      setOpenWelcomeModal(false);
    } catch (error) {
      console.error("Could not agree to terms and conditions:", error);
    }
  }, [openChatSessionId, updateTerms]);

  const termsContent = useMemo(() => {
    return (
      <div className="flex flex-grow flex-col items-stretch p-0 sm:p-8">
        <div className="m-auto w-full max-w-2xl">
          <div className="flex flex-col rounded-md bg-white">
            <div className="overflow-y-auto max-h-[calc(100vh-300px)]">
              <div className="prose prose-sm text-md font-semibold pr-4 flex-1 overflow-y-auto">
                <p>
                  <span className="text-xl font-semibold">
                    Experience Faster Risk Analsis with Provision. De-risk your
                    largest projects with Provision.
                  </span>{" "}
                  <br />
                  <br />
                  We provide best-in-class accuracy and speed for identifying
                  costly risks across complex contracts.{" "}
                  <span className="font-normal">
                    In this demo, you can access Provision's Chat functionality
                    and ask questions about a publically-available bid. If
                    you're interested in seeing other features such as automated
                    risk analysis, revision tracking, and more, please reach
                    out!
                  </span>
                </p>
              </div>
              <div className="text-gray-500 mt-6">
                <p className="">
                  By using this demo, you agree to Provision's{" "}
                  <a
                    className={"text-blue-400 underline"}
                    href="https://drive.google.com/file/d/1ec7VNtdk0_T9kLp7ncEGIAuwK_2stGOs/view?usp=drive_link"
                    target="_blank"
                    rel="noreferrer"
                  >
                    Terms of Service
                  </a>
                  , and{" "}
                  <a
                    className={"text-blue-400 underline"}
                    href="https://drive.google.com/file/d/1Delth1ApAOLYUsFxZwkq29GNQQW2nLuj/view?usp=drive_link"
                    target="_blank"
                    rel="noreferrer"
                  >
                    Privacy Policy
                  </a>
                  .
                </p>
                <br />
              </div>
            </div>
            <div className="flex w-full justify-between pt-4">
              <button
                type="button"
                className="inline-flex w-fit-content justify-center self-end rounded-md bg-blue-400 px-3 py-2 text-sm font-semibold text-white shadow-sm hover:bg-blue-500 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-indigo-600"
                onClick={onSalesClick}
              >
                I'm interested - set up a call with Sales
              </button>
              <button
                type="button"
                className="inline-flex w-fit-content items-center justify-center self-end rounded-md bg-[#f4cd0f] px-3 py-2 !text-gray-900 text-sm font-semibold text-white shadow-sm hover:bg-yellow-500 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-amber-600"
                onClick={onClose}
              >
                Sounds neat, show me!{" "}
                <ArrowRightIcon className="ml-2 w-4 h-4" />
              </button>
            </div>
          </div>
        </div>
      </div>
    );
  }, [onClose]);

  const welcomeContent = useMemo(() => {
    return (
      <DialogPanel className="transition-al relative transform overflow-hidden rounded-lg bg-white text-left shadow-xl">
        <div className="flex h-full flex-col">
          <div className="bg-gray-800">
            <div className="px-8">
              <div className="flex items-center justify-center p-6 text-lg text-white">
                <img
                  width={"50px"}
                  className="rounded-lg text-center"
                  src="/favicons/android-chrome-192x192.png"
                  alt="Provision Logo"
                />
                <span className="px-4 text-4xl font-bold text-white">
                  Provision
                </span>
              </div>
            </div>
          </div>
          {termsContent}
        </div>
      </DialogPanel>
    );
  }, [termsContent]);

  return (
    <Transition.Root
      show={openWelcomeModal && agreedToTerms === false}
      as={Fragment}
    >
      <Dialog
        as="div"
        className="relative z-50"
        initialFocus={cancelButtonRef}
        onClose={onCloseOverride}
      >
        <Transition.Child
          as={Fragment}
          enter="ease-out duration-300"
          enterFrom="opacity-0"
          enterTo="opacity-100"
          leave="ease-in duration-200"
          leaveFrom="opacity-100"
          leaveTo="opacity-0"
        >
          <div className="fixed inset-0 bg-gray-500 bg-opacity-75 transition-opacity" />
        </Transition.Child>

        <div className="fixed inset-0 z-10 overflow-y-auto">
          <div className="flex h-full min-h-full items-center justify-center p-4 text-center sm:items-center sm:p-0">
            <Transition.Child
              as={Fragment}
              enter="ease-out duration-300"
              enterFrom="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
              enterTo="opacity-100 translate-y-0 sm:scale-100"
              leave="ease-in duration-200"
              leaveFrom="opacity-100 translate-y-0 sm:scale-100"
              leaveTo="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
            >
              {welcomeContent}
            </Transition.Child>
          </div>
        </div>
      </Dialog>
    </Transition.Root>
  );
}

export default TermsAndConditions;
