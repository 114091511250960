import { ExclamationTriangleIcon } from "@heroicons/react/24/solid";
import React, { useEffect } from "react";
import { useNavigate } from "react-router-dom";
const NotFoundPage: React.FC = () => {
  const navigate = useNavigate();
  useEffect(() => {
    setTimeout(() => {
      navigate("/");
    }, 3000);
  });
  return (
    <div className="flex h-full flex-col text-2xl items-center justify-center border p-3">
      <div>
        <ExclamationTriangleIcon className="h-24 w-24 mb-4 text-amber-600" />
      </div>
      <p className="font-bold text-3xl mb-6">Page Not Found.</p>
      <p>Please refresh the page and try again.</p>
      <p>Redirecting to Home in 3 seconds....</p>
    </div>
  );
};
export default NotFoundPage;
