import React, { useMemo } from "react";
import AIChatSourceMessageButton from "./ai-chat-source-message-button";
import { ChatSessionMessageSource } from "../../../shared/interfaces/chat/chat-history.interface";

interface AIChatMessageWithSourcesProps {
  chatHistoryMessage: {
    message: string;
    message_sources?: ChatSessionMessageSource[];
  };
}

const AIChatSourceMessageButtonV2: React.FC<AIChatMessageWithSourcesProps> = ({
  chatHistoryMessage,
}) => {
  //eslint-disable-next-line
  let { message, message_sources = [] } = chatHistoryMessage;
  message = message?.trim() ?? "";

  const elements = useMemo(() => {
    // Split message into segments by placeholders, bold text, and newlines
    const segments = message
      .split(/(\[\d+\]|\*\*.*?\*\*|\\n)/g)
      .filter(Boolean);
    const result: JSX.Element[] = [];

    segments.forEach((segment, index) => {
      // Handle placeholders like [1], [2], etc
      if (/^\[\d+\]$/.test(segment)) {
        const placeholderIndex = parseInt(segment.slice(1, -1)) - 1;
        if (
          placeholderIndex >= 0 &&
          placeholderIndex < message_sources.length
        ) {
          const source = message_sources[placeholderIndex];
          result.push(
            <AIChatSourceMessageButton
              key={`source-${index}`}
              chatSessionMessageSource={source}
              sources={message_sources}
              sourceIndex={placeholderIndex}
            />
          );
        } else {
          result.push(<span key={`placeholder-${index}`}>{segment}</span>);
        }
      }
      // Handle bold text wrapped in **
      else if (/^\*\*(.*?)\*\*$/.test(segment)) {
        const boldText = segment.slice(2, -2);
        result.push(<strong key={`bold-${index}`}>{boldText}</strong>);
      }
      // Handle newlines
      else if (segment === "\\n") {
        result.push(<br key={`br-${index}`} />);
      }
      // Handle regular text
      else {
        result.push(<span key={`text-${index}`}>{segment}</span>);
      }
    });

    return result;
  }, [message, message_sources]);

  return <>{elements}</>;
};

export default AIChatSourceMessageButtonV2;
